import moment from "moment";
import { getAppDateFormat } from "../config";
import { dateModels } from "../models/dateModels";
import { noValueSymbol } from "./constant";

export const dateToHumanReadable = (date: string | null) => {
  const result = moment(date, dateModels.dateFormat);
  return result.isValid() ? result.format(getAppDateFormat()) : noValueSymbol;
};

export const convertTimeToMinutes = (time: string): number => {
  const minutesInOneHour = 60;
  const [hours, minutes] = time.split(":").map(Number);
  return hours * minutesInOneHour + minutes;
};

export const compareTimes = (
  time1: string,
  time2: string,
  minTime: string,
  maxTime: string
) => {
  const time1Minutes = convertTimeToMinutes(time1);
  const time2Minutes = convertTimeToMinutes(time2);
  const minTimeMinutes = convertTimeToMinutes(minTime);
  const maxTimeMinutes = convertTimeToMinutes(maxTime);

  let adjustedTime1Minutes = time1Minutes;
  let adjustedTime2Minutes = time2Minutes;

  if (minTimeMinutes > maxTimeMinutes) {
    if (adjustedTime1Minutes < minTimeMinutes) {
      adjustedTime1Minutes += 24 * 60;
    }
    if (adjustedTime2Minutes < minTimeMinutes) {
      adjustedTime2Minutes += 24 * 60;
    }
  }

  return adjustedTime1Minutes >= adjustedTime2Minutes;
};

export const splitTimeString = (timeString: string) => {
  const [hours, minutes] = timeString.split(":");
  const parsedHours = parseInt(hours);
  const parsedMinutes = parseInt(minutes);

  return [parsedHours, parsedMinutes];
};
const maxMinutesInOneHour = 59;
export const hoursInOneDay = 24;
export const defaultMinTime = "00:00";
export const defaultMaxTime = "23:59";


export const validateTime = ({
	time,
	minTime,
	maxTime,
	maxHoursLimit,
}: {
	time: string;
	minTime: string;
	maxTime: string;
	maxHoursLimit?: number;
}) => {
	const [hours, minutes] = splitTimeString(time);
	const [maxHours, maxMinutes] = splitTimeString(maxTime);
	const [minHours, minMinutes] = splitTimeString(minTime);
	const atTheTurnOfMidnight = minHours > maxHours;
	if (
		isNaN(hours) ||
		isNaN(minutes) ||
		isNaN(minHours) ||
		isNaN(minMinutes) ||
		isNaN(maxHours) ||
		isNaN(maxMinutes)
	) {
		return false;
	}

	if (minutes < 0 || minutes > maxMinutesInOneHour) {
		return false;
	}

	if (hours === maxHours && minutes > maxMinutes) {
		return false;
	}
	if (hours === minHours && minutes < minMinutes) {
		return false;
	}

	if (maxHoursLimit !== undefined && hours >= maxHoursLimit) {
		return false;
	}

	if (atTheTurnOfMidnight) {
		if (hours < minHours && hours > maxHours) {
			return false;
		}
	} else {
		if (hours < minHours || hours > maxHours) {
			return false;
		}
	}

	return true;
};

export const validateNumber = (value: string, minValue?: string | null, maxValue?: string | null) => {
  const numberValue = Number(value);
  if (isNaN(numberValue)) {
    // Se il valore non è un numero, restituisci falso
    return false;
  }

  if (minValue !== undefined && minValue !== null) {
    const min = Number(minValue);

    if ((minValue === "0" && numberValue < 0) || numberValue < min) {
      // Se il valore è inferiore a minValue, restituisci falso
     
      return false;
    }
  }

  if (maxValue !== undefined && maxValue !== null) {
    const max = Number(maxValue);

    if ((maxValue === "0" && numberValue > 0) || numberValue > max) {
      // Se il valore è superiore a maxValue, restituisci falso
      return false;
    }
  }

  // Se tutti i controlli passano, restituisci vero
  return true;
};

export const subtractMinute = (time: string): string => {
  let [parsedHours, parsedMinutes] = splitTimeString(time);

  const minuteIsNan = isNaN(parsedMinutes);

  if (parsedMinutes === 0) {
    if (parsedHours === 0) {	
      parsedHours = 23;	
      parsedMinutes = 59;	
    } else {	
      parsedHours -= 1;	
      parsedMinutes = maxMinutesInOneHour;	
    }
  } else {
    parsedMinutes -= 1;
  }
  let newHourStr =
    parsedHours < 10 ? "0" + parsedHours.toString() : parsedHours.toString();
  let newMinuteStr =
    parsedMinutes < 10
      ? "0" + parsedMinutes.toString()
      : parsedMinutes.toString();

  return `${newHourStr}:${minuteIsNan ? "00" : newMinuteStr}`;
};
