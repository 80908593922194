import { useEffect, useRef } from "react";
import classNames from "classnames";
import * as echarts from 'echarts';

import { ChartInterface } from "./ChartInterface";
import RequestIndicator from "../RequestIndicator/RequestIndicator";


const Chart = ({
  option,
  isResizable,
  isAwaiting,
  onLoad = () => null,
  className,
  style
}: ChartInterface) => {
  const chartWrapperRef = useRef<HTMLDivElement>( null );

  useEffect(() => {
    if ( !chartWrapperRef.current ) {
      return;
    }

    const chart = (
      echarts.getInstanceByDom( chartWrapperRef.current ) || 
      echarts.init( chartWrapperRef.current )
    );
    
    if ( isResizable ) {
      const onResizeWindow = () => chart.resize();

      window.addEventListener( 'resize', onResizeWindow );

      return () => {
        window.removeEventListener( 'resize', onResizeWindow );
      };
    }
  }, [ chartWrapperRef ]);

  useEffect(() => {
    if ( chartWrapperRef.current ) {
      const chart = echarts.getInstanceByDom( chartWrapperRef.current );

      if ( chart ) {
        chart.setOption( option );
        onLoad( chart );
      }
    }
  }, [ JSON.stringify( option ) ] )

  return (
    <div
      style={style}
      className={classNames(
        'chart-wrapper width-100 pos-relative',
        className
      )}
    >
      {isAwaiting ? (
        <RequestIndicator
          isLoading
          delay={1000}
          spinnerOptions={{ scale: 0.5 }}
          backgroundColor='backgroundAlpha50'
          className='pos-absolute zindex-10'
        />
      ): null }
      <div
        className='width-100 height-100'
        ref={chartWrapperRef}
      />
    </div>
  );
};

export default Chart;
