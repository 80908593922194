import styled from "styled-components";
import classNames from "classnames";
import { useAppTheme } from "../../hooks";

import { MainContentWrapperInterface } from "./MainContentWrapperInterface";
import "./MainContentWrapper.scss";


const Wrapper = styled.div`
  background: ${( props: any ) => props.theme.palettes.backgroundTertiary};

  &.dark {
    background: ${( props: any ) => props.theme.palettes.backgroundDarkest};
  }
`;

const MainContentWrapper = ({
  darkMode,
  className,
  children
}: MainContentWrapperInterface) => {
  const theme = useAppTheme();

  return (
    <Wrapper
      theme={theme}
      className={classNames(
        'main-content-wrapper flex-grow flex',
        className,
        {
          dark: darkMode
        }
      )}
    >
      <div className='main-content flex-grow flex'>
        <div className='inner-wrapper flex-grow flex flex-columns'>
          {children}
        </div>
      </div>
    </Wrapper>
  );
};

export default MainContentWrapper;