import { scoreFormModels } from "../models/scoreFormModels";

export const eventTypes: scoreFormModels.eventType[] = [
  {
    name: "speeding",
    unit: scoreFormModels.valueTypeEnum.number,
    unitDescr: "km/h",
  },
  {
    name: "nighttime",
    unit: scoreFormModels.valueTypeEnum.time,
    unitDescr: "common.time",
  },
  {
    name: "idle_time",
    unit: scoreFormModels.valueTypeEnum.number,
    unitDescr: "common.mins",
  },
  {
    name: "tot_km_driven",
    unit: scoreFormModels.valueTypeEnum.number,
    unitDescr: "km",
  },
  {
    name: "long_trip",
    unit: scoreFormModels.valueTypeEnum.hhmm,
    unitDescr: "common.HourMinuteFormat",
  },
  {
    name: "long_trip_km",
    unit: scoreFormModels.valueTypeEnum.number,
    unitDescr: "km",
  },
  {
    name: "fast_lane_change",
    unit: scoreFormModels.valueTypeEnum.number,
    unitDescr: "millig",
  },
  {
    name: "cornering",
    unit: scoreFormModels.valueTypeEnum.number,
    unitDescr: "millig",
  },
  {
    name: "crash",
    unit: scoreFormModels.valueTypeEnum.number,
    unitDescr: "g",
  },
  {
    name: "hardacceleration",
    unit: scoreFormModels.valueTypeEnum.number,
    unitDescr: "millig",
  },
  {
    name: "hardbrake",
    unit: scoreFormModels.valueTypeEnum.number,
    unitDescr: "millig",
    severityCanBeIncomplete: true,
  },
];

