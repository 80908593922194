export const svgIconsData: Record<string, any> = {
  eye: {
    viewbox: '0 0 22 15',
    transform: 'translate(-1 -4.5)',
    path: 'M12,6.5A9.77,9.77,0,0,1,20.82,12,9.822,9.822,0,0,1,3.18,12,9.77,9.77,0,0,1,12,6.5Zm0-2A11.827,11.827,0,0,0,1,12a11.817,11.817,0,0,0,22,0A11.827,11.827,0,0,0,12,4.5Zm0,5A2.5,2.5,0,1,1,9.5,12,2.5,2.5,0,0,1,12,9.5Zm0-2A4.5,4.5,0,1,0,16.5,12,4.507,4.507,0,0,0,12,7.5Z'
  },
  eyeShut: {
    viewbox: '0 0 22 19.15',
    transform: 'translate(-1 -2.5)',
    path: 'M12,6.55a9.77,9.77,0,0,1,8.82,5.5,9.647,9.647,0,0,1-2.41,3.12l1.41,1.41A11.8,11.8,0,0,0,23,12.05,11.834,11.834,0,0,0,8.36,5.12l1.65,1.65A10.108,10.108,0,0,1,12,6.55ZM10.93,7.69,13,9.76a2.5,2.5,0,0,1,1.28,1.28l2.07,2.07a4.679,4.679,0,0,0,.14-1.07A4.483,4.483,0,0,0,12,7.55,4.244,4.244,0,0,0,10.93,7.69ZM2.01,4.42,4.69,7.1A11.738,11.738,0,0,0,1,12.05a11.827,11.827,0,0,0,11,7.5,11.73,11.73,0,0,0,4.32-.82l3.42,3.42,1.41-1.41L3.42,3Zm7.5,7.5,2.61,2.61a.5.5,0,0,1-.12.02,2.5,2.5,0,0,1-2.5-2.5C9.5,12,9.51,11.97,9.51,11.92Zm-3.4-3.4,1.75,1.75a4.6,4.6,0,0,0-.36,1.78,4.505,4.505,0,0,0,6.27,4.14l.98.98a10.432,10.432,0,0,1-2.75.38,9.77,9.77,0,0,1-8.82-5.5A9.9,9.9,0,0,1,6.11,8.52Z'
  },
  filters: {
    viewbox: '0 0 18 18',
    path: 'M.818,3.079H3.406a2.279,2.279,0,0,0,4.24,0h9.536a.818.818,0,1,0,0-1.636H7.646a2.279,2.279,0,0,0-4.24,0H.818a.818.818,0,0,0,0,1.636M5.526,1.636a.624.624,0,1,1-.638.624.632.632,0,0,1,.638-.624M17.182,8.182H14.594a2.279,2.279,0,0,0-4.24,0H.818a.818.818,0,0,0,0,1.636h9.536a2.279,2.279,0,0,0,4.24,0h2.588a.818.818,0,1,0,0-1.636M12.474,9.624A.624.624,0,1,1,13.112,9a.632.632,0,0,1-.638.624m4.708,5.3H7.646a2.279,2.279,0,0,0-4.24,0H.818a.818.818,0,1,0,0,1.636H3.406a2.279,2.279,0,0,0,4.24,0h9.536a.818.818,0,1,0,0-1.636M5.526,16.364a.624.624,0,1,1,.638-.624.632.632,0,0,1-.638.624'
  },
  close: {
    viewbox: '0 0 18 18',
    path: 'M17.62,15.786a1.3,1.3,0,0,1-1.834,1.834L9,10.834,2.214,17.62A1.3,1.3,0,0,1,.38,15.786L7.166,9,.38,2.214A1.3,1.3,0,0,1,2.214.38L9,7.166,15.786.38A1.3,1.3,0,0,1,17.62,2.214L10.834,9Z'
  },
  calendar: {
    viewbox: '0 0 512 512',
    path: 'M369.12 37.06v-19.2c0-9.86-8-17.86-17.86-17.86S333.4 8 333.4 17.86v17.86H178.61V17.86c0-9.86-8-17.86-17.86-17.86s-17.86 8-17.86 17.86v19.2C67.2 43.69 23.82 91.92 23.82 172.65v202.42C23.82 462.09 73.73 512 160.75 512h190.51c87.02 0 136.93-49.91 136.93-136.93V172.65c0-80.73-43.38-128.96-119.07-135.59ZM142.89 72.61v16.7c0 9.86 8 17.86 17.86 17.86s17.86-8 17.86-17.86V71.45H333.4v17.86c0 9.86 8 17.86 17.86 17.86s17.86-8 17.86-17.86v-16.7c54.59 5.5 81.93 37.1 83.1 96.23H59.78c1.17-59.14 28.51-90.73 83.1-96.23Zm208.37 403.67H160.75c-68.09 0-101.21-33.12-101.21-101.21V204.56h392.93v170.51c0 68.09-33.12 101.21-101.21 101.21Zm16.65-179.79c0 13.16-10.65 23.81-23.81 23.81s-23.88-10.65-23.88-23.81 10.6-23.81 23.77-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Zm0 71.44c0 13.16-10.65 23.81-23.81 23.81s-23.88-10.65-23.88-23.81 10.6-23.81 23.77-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Zm-88.07-71.44c0 13.16-10.65 23.81-23.81 23.81s-23.86-10.65-23.86-23.81 10.58-23.81 23.74-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Zm0 71.44c0 13.16-10.65 23.81-23.81 23.81s-23.86-10.65-23.86-23.81 10.58-23.81 23.74-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Zm-88.14-71.44c0 13.16-10.65 23.81-23.81 23.81s-23.86-10.65-23.86-23.81 10.58-23.81 23.74-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Zm0 71.44c0 13.16-10.65 23.81-23.81 23.81s-23.86-10.65-23.86-23.81 10.58-23.81 23.74-23.81h.12c13.16 0 23.81 10.65 23.81 23.81Z'
  },
  edit: {
    viewbox: '0 0 19.4 19.4',
    path: 'M4.5 15.8h.4l2.6-.4c.6-.1 1.1-.4 1.5-.8l6.6-7c1.7-1.8 1.6-3.5-.1-5.1-1.8-1.7-3.5-1.6-5.1.2l-1.3 1-5.5 5.8c-.3.5-.6 1-.7 1.5l-.3 2.6c-.1.6.1 1.2.5 1.6.4.4.9.6 1.4.6zm8.4-13.4c.7.1 1.3.4 1.7.9 1.5 1.4 1.1 2.4.1 3.4l-.9.9c-1.7-.3-3.1-1.6-3.5-3.4l.9-.9c.4-.5 1-.9 1.7-.9zm-8.7 8.8c.1-.3.2-.6.4-.8l4.8-5.1c.6 1.6 1.9 2.8 3.5 3.3L8 13.7c-.2.2-.5.3-.8.4l-2.6.4c-.2.1-.5 0-.6-.1-.2-.2-.2-.4-.2-.6l.4-2.6zm13.4 6.6c0 .3-.3.6-.6.6H2.4c-.3 0-.6-.3-.6-.6s.3-.6.6-.6H17c.3 0 .6.3.6.6z'
  }
};