import { useTranslation } from "react-i18next";
import ConfirmationModal from "../../shared-components/ConfirmationModal/ConfirmationModal";
import { DeleteScoreSetModalInterface } from "./DeleteScoreSetModalInterface";
import "./DeleteScoreSetModal.scss";
const DeleteScoreSetModal = ({
	scoreSetsName,
	onAction,
}: DeleteScoreSetModalInterface) => {
	const { t } = useTranslation("main");

	const content = (
		<div className="delete-score-set-modal-content">
			<p className="text-small mb-1">{scoreSetsName?.length && scoreSetsName?.length > 1 ? t("modals.deleteScoreSets") : t("modals.deleteScoreSet")} </p>
			<p className="bold text-small mb-1">{scoreSetsName?.join(", ")}</p>
		</div>
	);

	return (
		<ConfirmationModal
			title={t("common.deletion")}
			size="small"
			content={content}
			actionLabel={t("common.delete") as string}
      onAction={onAction}
      confirmationButtonColor="danger"
		/>
	);
};

export default DeleteScoreSetModal;
