import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { scoreFormModels } from "../models/scoreFormModels";

export enum ScoreSetToLoadStates {
  noData = 'no_data',
  awaiting = 'awaiting',
  loaded = 'loaded'
}

export interface ScoreFormState {
  isEditModeActive: boolean,
  shouldReset : boolean,
  scoreSetToLoad: {
    name?: string;
    id: string | number;
    status: ScoreSetToLoadStates;
    entries: scoreFormModels.scoreSetEvent[]
  }
}

export const scoreFormSlice = createSlice({
  name: 'functionalities',
  initialState: {
    isEditModeActive: false,
    shouldReset : false,
    scoreSetToLoad: {
      id: '',
      status: ScoreSetToLoadStates.noData,
      entries: []
    }
  } as ScoreFormState,
  reducers: {
    setIsEditModeActive: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isEditModeActive: action.payload,
      };
    },
    setShouldReset: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        shouldReset: action.payload,
      };
    },
    updateScoreSetToLoad: ( state, action: PayloadAction<{ scoreSetID: string | number, data: scoreFormModels.scoreSetEvent[], scoreSetName?: string }> ) => {
      return {
        ...state,
        scoreSetToLoad: {
          name: action.payload.scoreSetName,
          id: action.payload.scoreSetID,
          status: ScoreSetToLoadStates.awaiting,
          entries: action.payload.data
        }
      };
    },
    markScoreSetAsLoaded: ( state ) => {
      return {
        ...state,
        scoreSetToLoad: {
          ...state.scoreSetToLoad,
          status: ScoreSetToLoadStates.loaded
        }
      };
    }
  }
});

export const {
  updateScoreSetToLoad,
  markScoreSetAsLoaded,
  setShouldReset,
  setIsEditModeActive
} = scoreFormSlice.actions;

export const getIsEditModeActive = ( state: RootState ) => {
  return state.scoreForm.isEditModeActive;
}
export const getShouldReset = ( state: RootState ) => {
  return state.scoreForm.shouldReset;
}

export const selectScoreSetToLoad = ( state: RootState ) => {
  return state.scoreForm.scoreSetToLoad;
}

export default scoreFormSlice.reducer;