import { createContext, ReactElement } from "react";


export interface ConfirmationModalContextInterface {
  open: ( modalComponent: ReactElement ) => void;
  close: () => void;
}

export const ConfirmationModalContext = createContext<ConfirmationModalContextInterface>({
  open: () => null,
  close: () => null
});