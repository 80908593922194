import { SetStateAction, useCallback, useEffect, useState } from "react";
import Chart from "../../shared-components/Chart/Chart";
import { TabbedChartsInterface } from "./TabbedChartsInterface";
import CustomButton from "../../shared-components/CustomButton/CustomButton";
import { GroundButtonTypes, GroundButtonVariants } from "@greenvulcano/ground-react";
import { chartModels } from "../../models/chartModels";
import "./TabbedCharts.scss"


const createChart = (options : Record<string,any> ,style : any,setChartInstance :SetStateAction<any>) => {
  return (
		<Chart
			style={style}
			option={options}
			onLoad={(chartInstance) => {
        		setChartInstance(chartInstance);
			}}
		/>
	);
}

const TabbedCharts = ({ setChartInstance, tabbedChartsArray, setCurrentChartName }: TabbedChartsInterface) => {
  const [currentChart, setCurrentChart] = useState<JSX.Element | undefined>();
  const [currentChartId, setCurrentChartId] = useState<string | undefined>();

  const initializeChart = useCallback(
		(chartData: chartModels.ChartData) => {
			if (currentChartId === chartData.id) return;
			const chart = createChart(
				chartData.options,
				chartData.style,
				setChartInstance
			);
			setCurrentChart(chart);
			setCurrentChartId(chartData.id);
			setCurrentChartName(chartData.name);
		},
		[setChartInstance, currentChartId]
	);


  useEffect(() => {
    if (currentChart === undefined) {
      initializeChart(tabbedChartsArray[0])
    }
  }, [initializeChart, currentChart, tabbedChartsArray])

	return (
		<div className="tabbed-chart-container">
			<div className="tabbed-chart-tabs">
				{tabbedChartsArray.map((chartData) => (
					<CustomButton
						key={chartData.name}
						type={GroundButtonTypes.button}
						variant={GroundButtonVariants.ghost}
						text={chartData.name}
						className={`${currentChartId === chartData.id ? "selected": ""}`}
						onClick={() => {
							initializeChart(chartData);
						}}
					/>
				))}
			</div>
			{currentChart}
		</div>
	);
}

export default TabbedCharts;