import { createBrowserRouter, Navigate } from "react-router-dom";
import ErrorPage from "./ErrorPage/ErrorPage";
import Login from "./routes/Login/Login";
import PasswordRecovery from "./routes/PasswordRecovery/PasswordRecovery";
import Root from "./routes/Root/Root";
import MainPage from "./routes/MainPage/MainPage";

export const paths = {
	admin: "/admin",
	login: "/login",
	recoveryPassword: "/recovery-password",
};

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Navigate to={paths.admin} />,
		errorElement: <ErrorPage />,
	},
	{
		path: paths.admin,
		element: <Root />,
		children: [
			{
				path: 'home',
				element: <MainPage />,
			}
		],
	},
	{
		path: paths.login,
		element: <Login />,
	},
	{
		path: paths.recoveryPassword,
		element: <PasswordRecovery />,
	},
]);
