import { env } from "../env";

const apiDomain = `${env.REACT_APP_API_DOMAIN}`;
const apiBase   = apiDomain + "/api/v1";

export const apiEndpoints: Record<string, string> = {
	// Authentication
	login: `${apiBase}/login`,
	logout: `${apiBase}/logout`,
	userInfo: `${apiBase}/users/me/info`,
	restorePassword: `${apiBase}/account/restore-password`,

	// Score Sets
	scoreSets: `${apiBase}/score-set`,
	editScoreSet: `${apiBase}/score-set/edit`,
	scoreSetDetails: `${apiBase}/score-set/event/:id`,
	calculateScoreSet: `${apiBase}/score-set/calculate`,
	deleteScoreSet:`${apiBase}/score-set/delete`,
	statesList: `${apiBase}/score-set/status`,
	tripsDistributionOneSecond: `${apiBase}/score-set/trip-one-second`,
	tripsDistributionFiveMinutes: `${apiBase}/score-set/trip-five-minutes`,

	// Fleets
	fleets: `${apiBase}/score-set/fleet`,
	applyFleet: `${apiBase}/score-set/fleet/confirm`,

	// Functionalities
	eventTypes: `${apiBase}/score-set/event-types`,
	scoreStates: `${apiBase}/score-set/status`,
};
