import { 
  combineReducers, 
  createAction,
  AnyAction
} from "@reduxjs/toolkit";

import userSlice from "./userSlice";
import settingsSlice from "./settingsSlice";
import functionalitiesSlice from "./functionalitiesSlice";
import scoreFormSlice from "./scoreFormSlice";
import loginSlice from "./loginSlice";

const appReducer = combineReducers({
  user: userSlice,
  settings: settingsSlice,
  functionalities: functionalitiesSlice,
  scoreForm: scoreFormSlice,
  login : loginSlice
});

export const logout = createAction( 'logout' );

export const rootReducer = ( state: any, action: AnyAction ) => {
  if ( action.type === logout.toString() ) {
    return appReducer( undefined, { type: undefined } );
  }

  return appReducer( state, action );
};