import axios, { AxiosError } from "axios";
import { getApiEndpoint } from "../config";
import { AuthService } from "./AuthService";
import i18n from "../i18n";

export const FleetsService = {
  getFleets: async ( name: string ) => {
    try {
      const { data: responseData } = await axios.get( 
        getApiEndpoint( 'fleets' ), 
        {
          params: { fleet_name: name },
          ...AuthService.getAuthHeaders()
        }
      );
      return Promise.resolve( responseData.data );
    }
    catch (error) {
      const typeError = error as AxiosError;
      const errorMessage = `${i18n.t('main:errors.failedToRetrieve')} ${i18n.t('main:common.listOfFleets').toLowerCase()}`;
      typeError.message = errorMessage;
		  return  Promise.reject(typeError.message);
    }
  },
  applyFleet: async ( scoreSetID: string, fleetID: string ) => {
    try {
      const { data: responseData } = await axios.post( 
        getApiEndpoint( 'applyFleet' ), 
        {
          id_score_set: Number( scoreSetID ),
          fleet: [ Number( fleetID ) ]
        },
        AuthService.getAuthHeaders()
      );

      return Promise.resolve( responseData.data );
    }
    catch (error) {
      const typeError = error as AxiosError;
      const errorMessage = i18n.t('main:errors.failedToApplyFleetOnScoreset');
      typeError.message = errorMessage;
		  return  Promise.reject(typeError.message);
    }
  },
};