import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAppTheme } from "../../hooks";
import { useUserQuery } from "../../hooks/queries";
import { setDocumentTitle } from "../../config";

import Logo from "../Logo/Logo";
import Footer from "../Footer/Footer";
import RequestIndicator from "../RequestIndicator/RequestIndicator";

import "./LoginTemplate.scss";


const TemplateWrapper = styled.div`
  background-color: ${( props: any ) => props.theme.palettes.backgroundSecondary};
`;

const FormWrapper = styled.div`
  background: ${( props: any ) => props.theme.palettes.background};
  border-color: ${( props: any ) => props.theme.palettes.borderTertiaryLight};

  h1 {
    background: ${( props: any ) => props.theme.palettes.backgroundDarker};
    border-bottom-color: ${( props: any ) => props.theme.palettes.borderTertiaryLight};
  }

  p {
    color: ${( props: any ) => props.theme.palettes.textLight};
  }

  input {
    border-color: ${( props: any ) => props.theme.palettes.border};

    &::placeholder {
      color: ${( props: any ) => props.theme.palettes.textSecondaryLighter};
    }
  }

  .ground-text-input-toggle-btn:not(.active) {
    path {
      fill: ${( props: any ) => props.theme.palettes.border};
    }
  }
`;

const LoginTemplate = ({ children }: any) => {
  const theme    = useAppTheme();
  const location = useLocation();
  const query    = useUserQuery();
  const loginHeight = 200;

  useEffect(() => {
    setDocumentTitle();
  }, [ location.pathname ]);

  if ( query.isSuccess ) {
    return <Navigate to='/admin' />
  }

  if ( !( query.isIdle || query.isError ) ) {
    return (
      <RequestIndicator
        isLoading
        delay={500}
        className='flex-grow'
      />
    );
  }

  return (
    <TemplateWrapper
      theme={theme}
      className='login-template height-100v flex'
    >
      <div className='page-content flex flex-center'>
        <FormWrapper
          theme={theme}
          className='form-wrapper flex flex-columns felx-hcenter'
        >
          <Logo configId='loginLogo' height={loginHeight} />
          {children}
        </FormWrapper>
      </div>
      <Footer />
    </TemplateWrapper>
  );
};

export default LoginTemplate;