import { AppDateFormats } from "../constants";

import { ReactComponent as loginLogo } from "../images/logo-login.svg";
import { ReactComponent as navbarLogo } from "../images/logo-navbar.svg";


export const appConfig: Record<string, any> = {
  appTitle: "Encontrack EDS",
  defaultLocale: "en-US",
  currency: "USD",
  dateFormat: AppDateFormats.dmy,
  themeName: "DefaultTheme",
  loginLogo: loginLogo, // Can be a URL too.
  loginLogoHeight: 58, // px. Applied only to non SVG images.
  logo: navbarLogo, // Can be a URL too.
  logoHeight: 48, // px. Applied only to non SVG images.
  showFooter: true,
  copyrightsNotice: <>&copy; 2023 Encontrack</>,

  maxWeight: 100,

  // React-query config.
  staleTime: 10 * (60 * 1000), // 10 minutes
  cacheTime: 15 * (60 * 1000), // 15 minutes
};
