import { useQuery } from "react-query";
import { AuthService } from "../services/AuthService";

import { useAppDispatch } from "../store/hooks";
import { updateUserInfo, UserState } from "../store/userSlice";
import * as ScoreSetService from "../services/ScoreSetService";
import { scoreModels } from '../models/scoreModels';
import { AxiosError } from "axios";


export const useUserQuery = () => {
  const token       = AuthService.getToken();
  const appDispatch = useAppDispatch();

  const query = useQuery({
    queryKey: [ 'userQuery', token ],
    retry: false,
    enabled: Boolean( token ),
    queryFn: AuthService.getUserInfo,
    onSuccess: ( data: UserState ) => {
      appDispatch( updateUserInfo( data ) );
    }
  });

  return query;
};

type useScoreSetListInterface = {
  filters: ScoreSetService.getScoreSetsInterface,
  onSuccess?: (data: scoreModels.scoreSet[]) => void,
  onError : (error : AxiosError) => void
}

export const useScoreSetList = ({filters,
  onSuccess,
  onError} : useScoreSetListInterface) => {
  return useQuery({
    queryKey: ["scoreSetList", { filters }],
    queryFn:  () => ScoreSetService.getScoreSets(filters),
    onSuccess,
    onError
  })
};
