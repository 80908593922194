import axios, { AxiosError } from "axios";
import { getApiEndpoint } from "../config";
import { AuthService } from "./AuthService";
import { normalizeData } from "../utils";

import { eventTypes } from "../config/event-types";
import { scoreModels } from "../models/scoreModels";
import { scoreFormModels } from "../models/scoreFormModels";
import i18n from "../i18n";

export const FunctionalitiesService = {
  getEventTypes: async () => {
    try {
      const {
        data: responseData,
      }: { data: { data: scoreFormModels.eventTypeBE[] } } = await axios.get(
        getApiEndpoint("eventTypes"),
        AuthService.getAuthHeaders()
      );

      const normalizedEventTypes = normalizeData(eventTypes, "name");
      const processedData = responseData.data.map((dataItem) => {
        const eventName = dataItem.name.toLowerCase();
        const eventType = normalizedEventTypes[eventName] || {};

        return {
          unit: eventType.unit,
          unitDescr: eventType.unitDescr,
          disabled: !parseInt(dataItem.is_active),
          severityCanBeIncomplete: eventType.severityCanBeIncomplete,
          id: dataItem.id,
          name: dataItem.name,
          max_value: dataItem.max_value,
          min_value: dataItem.min_value,
        };
      });

      return Promise.resolve(processedData as scoreFormModels.eventType[]);
    } catch (error) {
      const typeError = error as AxiosError;
      const errorMessage = `${i18n.t('main:errors.failedToRetrieve')} ${i18n.t('main:common.listOfEventTypes').toLowerCase()}`;
      typeError.message = errorMessage;
		  return  Promise.reject(typeError.message);
    }
  },
  getScoreStates: async () => {
    try {
      const { data: responseData } = await axios.get(
        getApiEndpoint("scoreStates"),
        AuthService.getAuthHeaders()
      );
      return Promise.resolve({scoreStates : responseData.data as scoreModels.statesList[]});
    } catch (error) {
      const typeError = error as AxiosError;
      const errorMessage = `${i18n.t('main:errors.failedToRetrieve')} ${i18n.t('main:common.listOfStates').toLowerCase()}`;
      typeError.message = errorMessage;
		  return  Promise.reject(typeError.message);
    }
  }

};
