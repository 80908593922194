import axios, { AxiosError } from "axios";
import { getApiEndpoint } from "../config";
import { AuthService } from "./AuthService";
import { dateModels } from "../models/dateModels";
import i18n from "../i18n";


export type getScoreSetsInterface = {
	name: string;
	dates: {
		start?: dateModels.dateString;
		end?: dateModels.dateString ;
	};
	checkedStatuses: number[];
}

export const getScoreSets = async ({
	name,
	dates,
	checkedStatuses,
}: getScoreSetsInterface) => {
	try {
		const response = await axios.get(getApiEndpoint("scoreSets"), {
      params: {
        name,
        start_date: dates?.start,
        end_date: dates?.end,
        status: checkedStatuses?.join(","),
      },
      ...AuthService.getAuthHeaders(),
    });

		return Promise.resolve(
	      response.data.data.map((scoreSet: any) => {
        	return {
          	...scoreSet,
          	is_default: !!parseInt(scoreSet.is_default as string),
        	};
      	})
    	);
	}
	catch (error) {
		const typeError = error as AxiosError;
		const errorMessage = `${i18n.t('main:errors.failedToRetrieve')} ${i18n.t('main:common.listOfScoresets').toLowerCase()}`;
		typeError.message = errorMessage;
		return  Promise.reject(typeError.message);
	}
};

export const getScoreSetDetails = async ( id: string ) => {
	try {
		const { data: responseData } = await axios.get(
			getApiEndpoint( 'scoreSetDetails', { id } ),
			AuthService.getAuthHeaders()
		);

		return Promise.resolve( responseData.data );
	}
	catch (error) {
		const typeError = error as AxiosError;
		const errorMessage = `${i18n.t('main:errors.failedToRetrieve')} ${i18n.t('main:common.listOfScoresetDetails').toLowerCase()}`;
		typeError.message = errorMessage;
		return  Promise.reject(typeError.message);
	}
};

export const saveScoreSet = async ( payload: { name: string, score: Record<string, any>[] } ) => {
	try {
		const { data: responseData } = await axios.post(
			getApiEndpoint( 'scoreSets' ),
			payload,
			AuthService.getAuthHeaders()
		);

		return Promise.resolve( String( responseData.data.score_set_id ) );
	}
	catch ( error) {
		const typeError = error as AxiosError;
		const errorMessage = i18n.t('main:errors.failedToSaveScoreset');
		typeError.message = errorMessage;
		return  Promise.reject(typeError.message);
	}
};

export const editScoreSet = async ( payload: {id_score_set: string, name: string, score: Record<string, any>[] } ): Promise<string> => {
	try {
		const { data: responseData } = await axios.post(
			getApiEndpoint( 'editScoreSet' ),
			payload,
			AuthService.getAuthHeaders()
		);

		return Promise.resolve( String( responseData.data.score_set_id ) );
	}
	catch (error) {
		const typeError = error as AxiosError;
		const errorMessage = i18n.t('main:errors.failedToEditScoreset');
		typeError.message = errorMessage;
		return  Promise.reject(typeError.message);
	}
};

export const calculateScoreSet = async ( scoreSetID: string | number ) => {
	try {
		const { data: responseData } = await axios.get(
			getApiEndpoint( 'calculateScoreSet' ),
			{
				params: { id_score_set: scoreSetID },
				...AuthService.getAuthHeaders()
			},
		);

		return Promise.resolve( responseData );
	}
	catch (error) {
		const typeError = error as AxiosError;
		const errorMessage = i18n.t('main:errors.failedToCalculateScoreset');
		typeError.message = errorMessage;
		return  Promise.reject(typeError.message);
	}
};


export const getTripsDistributionOneSecond = async (
	scoreSetsIDs: Array<string | number>
) => {
	try {
		const { data: responseData } = await axios.get(
			getApiEndpoint("tripsDistributionOneSecond"),
			{
				params: { id_score_set: scoreSetsIDs.join() },
				...AuthService.getAuthHeaders(),
			}
		);
		return Promise.resolve(responseData.data);
	} catch (error) {
		const typeError = error as AxiosError;
		const errorMessage = i18n.t('main:errors.failedToRetrieveOneSecondChartData');
		typeError.message = errorMessage;
		return  Promise.reject(typeError.message);
	}
};

export const deleteScoreSet = async (scoreSetsIDs: Array<number>) => {
	try {
		const { data: responseData } = await axios.post(
			getApiEndpoint("deleteScoreSet"),
			{
				id_score_set: scoreSetsIDs 
				
			},
			AuthService.getAuthHeaders(),
		);
		
		return Promise.resolve(responseData.data);
	} catch (error) {
		const typeError = error as AxiosError;
		const errorMessage = i18n.t('main:errors.failedToDeleteScoreSet');
		typeError.message = errorMessage;
		return  Promise.reject(typeError.message);

	}
};

export const getTripsDistributionFiveMinutes = async (
	scoreSetsIDs: Array<string | number>
) => {
	try {
		const { data: responseData } = await axios.get(
			getApiEndpoint("tripsDistributionFiveMinutes"),
			{
				params: { id_score_set: scoreSetsIDs.join() },
				...AuthService.getAuthHeaders(),
			}
		);

		return Promise.resolve(responseData.data);
	} catch (error) {
		const typeError = error as AxiosError;
		const errorMessage = i18n.t('main:errors.failedToRetrieveFiveMinutesChartData');
		typeError.message = errorMessage;
		return  Promise.reject(typeError.message);
	}
};
