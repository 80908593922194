import { scoreModels } from "../models/scoreModels";
import { attemptTranslation } from "./i18n";

export const getChartOptions = ({
    t,
    theme,
    data,
    scoreSetsInfo
  }: Record<string, any> ) => {
    const levelsIDs = [
      'excellent',
      'very good',
      'good',
      'low',
      'poor'
    ];
    const legendData: string[]   = [];
    const series: any            = [];
    const seriesColors: string[] = theme.distributionChartColors;

    const dataMap = data.reduce((
      _map: Record<string, Record<string, scoreModels.scoreSetTrip>>,
      dataItem: scoreModels.scoreSetTrip
    ) => {
      const key = dataItem.score_level.toLowerCase();

      if ( _map[key] ) {
        _map[key][String( dataItem.id )] = dataItem;
      }
      else {
        _map[key] = { [String( dataItem.id )]: dataItem };
      }

      return _map;
    }, {} );

    scoreSetsInfo.forEach(( infoItem: any, idx: number ) => {
      legendData.push( infoItem.name );

      series.push({
        name: infoItem.name,
        data: levelsIDs.map(( levelID: string ) => {
          const key = String( infoItem.id );

          if ( !( dataMap[levelID] && dataMap[levelID][key] ) ) {
            return 0;
          }

          return Number( dataMap[levelID][key].trip_num );
        }),
        type: 'bar',
        barMaxWidth: 20,
        itemStyle: {
          color: seriesColors[idx] || '',
          borderRadius: [4, 4, 0, 0]
        }
      })
    });

    return {
      legend: {
        show: true,
        data: legendData,
        icon: 'roundRect',
        itemGap: 30,
        right: 0,
        textStyle: {
          fontFamily: theme.fontFamilies.primary,
          fontSize: 16
        }
      },
      grid: {
        left: 40,
        right: 5,
        bottom: 30
      },
      tooltip: {
        show: true
      },
      xAxis: {
        type: 'category',
        data: levelsIDs.map(( item: string ) => attemptTranslation( item, t )),
        axisLabel: {
          color: theme.palettes.text,
          fontSize: 16,
          fontFamily: theme.fontFamilies.primary,
        },
        axisLine: {
          lineStyle: {
            color: theme.palettes.border
          }
        }
      },
      yAxis: {
        type: 'value',
        splitNumber: 4,
        axisLabel: {
          color: theme.palettes.text,
          fontSize: 16,
          fontFamily: theme.fontFamilies.primary,
          formatter: ( value: string ) => {
            if ( Number( value ) >= 1000 ) {
              return Math.round( Number( value ) / 1000 ) + 'k';
            }

            return value;
          },
        },
      },
      series
    };
  };