import { FC, ReactElement, useState } from "react";
import styled from "styled-components";
import { useAppTheme } from "../../hooks";
import { applyErrorBoundary } from "../../utils/error-boundary";

import { ConfirmationModalContext } from "../../contexts/ConfirmationModalContext";
import { ConfirmationModalContextInterface } from "../../contexts/ConfirmationModalContext";
import { ConfirmationModalProviderInterface } from "./ConfirmationModalProviderInterface";
import { ConfirmationModalInterface } from "../ConfirmationModal/ConfirmationModalInterface";
import "./ConfirmationModalProvider.scss";


const ModalWrapper = styled.div`
  background: ${( props: any ) => `${props.theme.palettes.text}80`};
`;

const ConfirmationModalProvider = ({
  children
}: ConfirmationModalProviderInterface ) => {
  const theme = useAppTheme();
  const [ modal, setModal ] = useState<ReactElement | null>( null );

  const contextValue: ConfirmationModalContextInterface = {
    open: ( modalComponent: ReactElement ) => {
      setModal( modalComponent );
    },
    close: () => {
      setModal( null )
    }
  };

  return (
    <ConfirmationModalContext.Provider value={contextValue}>
      {modal ? (
        <>
          {children}
          <ModalWrapper
            theme={theme}
            className='modal-window-wrapper flex flex-center'
          >
            {modal}
          </ModalWrapper>
        </>
      ) : children}
    </ConfirmationModalContext.Provider>
  );
};

export default applyErrorBoundary( ConfirmationModalProvider );