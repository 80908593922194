import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppTheme, useConfirmationModal } from "../../hooks";
import { scoreFormModels } from "../../models/scoreFormModels";
import { selectFunctionality } from "../../store/functionalitiesSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setIsEditModeActive, setShouldReset, updateScoreSetToLoad } from "../../store/scoreFormSlice";
import { isObjectEmpty, prepareDataForLoading } from "../../utils";
import { attemptTranslation } from "../../utils/i18n";

import {
	GroundButtonTypes,
	GroundButtonVariants,
	GroundCheckbox,
} from "@greenvulcano/ground-react";
import ConfirmationModal from "../../shared-components/ConfirmationModal/ConfirmationModal";

import CustomButton from "../../shared-components/CustomButton/CustomButton";
import "./ViewScoreModal.scss";
import { ViewScoreModalInterface } from "./ViewScoreModalInterface";
import { infinitySymbol, infinityValue, noValueSymbol } from "../../utils/constant";

const HeadingsWrapper = styled.div`
	color: ${(props: any) => props.theme.palettes.textLight};
	border-bottom-color: ${(props: any) =>
		props.theme.palettes.borderQuaternaryAlpha60};
`;

const ScoreRow = styled.div`
	border-bottom-color: ${(props: any) =>
		props.theme.palettes.borderQuaternaryAlpha60};

	.values-set-item {
		border-left-color: ${(props: any) => props.theme.palettes.borderQuaternary};
	}
`;

const ViewScoreModal = ({
	scoreSet,
	scoreSetName,
	scoreSetID,
}: ViewScoreModalInterface) => {
	const { t } = useTranslation("main");
	const theme = useAppTheme();
	const appDispatch = useAppDispatch();
	const confirmationModal = useConfirmationModal();
	const eventTypes: scoreFormModels.eventType[] = useAppSelector(selectFunctionality("eventTypes"));
	const [checkedItemsIds, setCheckedItemIds] = useState([] as string[]);
	const [isLoading, setIsLoading] = useState(false);

	const severityLevels = [
    scoreFormModels.SeverityIdEnum.low,
    scoreFormModels.SeverityIdEnum.medium,
    scoreFormModels.SeverityIdEnum.high,
  ];

	const onChangeCheckedHandler = (checked: boolean, id: string) => {
		if (checked) {
			setCheckedItemIds((ids: string[]) => [...ids, id]);
		} else {
			setCheckedItemIds((ids: string[]) => {
				return ids.filter((elem: string) => elem !== id);
			});
		}
	};

	const onCloseModalHandler = () => {
		confirmationModal.close();
	};

	const onActionHandler = (close: Function) => {
		const data  = scoreSet
      		.filter((item: scoreFormModels.scoreSetEvent) =>
        		checkedItemsIds.includes(String(item.idEventType))
      		)

		appDispatch(
			updateScoreSetToLoad({
				scoreSetID,
				data: prepareDataForLoading(data),
			})
		);
			appDispatch(setIsEditModeActive(false));
			appDispatch(setShouldReset(true));
		close();
	};

	useEffect(() => {
      const ids = scoreSet
        .map((item: scoreFormModels.scoreSetEvent) => {
            return String(item.idEventType);
        })
        .filter((id): id is string => id !== undefined);

      setCheckedItemIds(ids);
  	}, []);

	const buttons = (
		<>
			<CustomButton
				disabled={isLoading}
				variant={GroundButtonVariants.ghost}
				type={GroundButtonTypes.button}
				text={t("common.goBack") as string}
				onClick={onCloseModalHandler}
			/>
			<CustomButton
				type={GroundButtonTypes.button}
				disabled={!checkedItemsIds.length}
				text={t("common.loadScore") as string}
				isLoading={isLoading}
				onClick={() =>
					setIsLoading(Boolean(onActionHandler(confirmationModal.close)))
				}
			/>
		</>
	);

	const content = (
		<div className="view-score-modal-content">
			<HeadingsWrapper theme={theme} className="headings-wrapper flex">
				<h4 className="event-label-item">{t("common.riskEvent")}</h4>
				{severityLevels.map((severityID: any) => {
					return (
						<div
							key={"severityHeadings_" + severityID}
							className="values-set-item flex-grow flex"
						>
							<h4 className="value-item flex-grow">{t("common.from")}</h4>
							<h4 className="value-item flex-grow">{t("common.to")}</h4>
							<h4 className="value-item flex-grow">{t("common.weight")}</h4>
						</div>
					);
				})}
			</HeadingsWrapper>
			{eventTypes.map((item: scoreFormModels.eventType) => {
				const eventData =
					scoreSet.find(
						(dataItem: scoreFormModels.scoreSetEvent) =>
							String(dataItem.idEventType) === String(item.id)
					) || ({} as scoreFormModels.scoreSetEvent);
				const eventDataContainsNullValue = Object.values(eventData).some(
          			(value) => value === null
        		);

				return (
					<ScoreRow
						key={"row_" + item.name}
						theme={theme}
						className="score-row flex"
					>
						<div className="event-label-item flex flex-vcenter gap-1">
							<GroundCheckbox
								color="secondary"
								disabled={isObjectEmpty(eventData) || item.disabled }
								checked={checkedItemsIds.includes( String(eventData.idEventType)) && !item.disabled }
								onChange={(event: any) =>
									onChangeCheckedHandler(
										event.target.checked,
										String(eventData.idEventType)
									)
								}
							/>
							<div className="flex flex-bottom gap-1/2">
								<strong className={item.name + "-label"}>
									{attemptTranslation(item.name, t)}
								</strong>
								{item.unitDescr ? (
									<span className="text-xsmall">({t(item.unitDescr)})</span>
								) : null}
							</div>
						</div>
						{severityLevels.map((severityID: any, idx: number) => {
							const fromKey = (severityID +
								"SeverityValueFrom") as keyof scoreFormModels.scoreSetEvent;
							const toKey = (severityID +
								"SeverityValueTo") as keyof scoreFormModels.scoreSetEvent;
							const weightKey = (severityID +
								"SeverityWeight") as keyof scoreFormModels.scoreSetEvent;

							return (
								<div
									key={item.name + "_" + severityID + "_" + idx}
									className="values-set-item flex-grow flex gap-1"
								>
									<strong className="value-item flex-grow">
										{eventData[fromKey] === infinityValue
											? infinitySymbol
											: eventData[fromKey] || 0 === eventData[fromKey]
											? eventData[fromKey]
											: noValueSymbol}
									</strong>
									<strong className="value-item flex-grow">
										{eventData[toKey] === infinityValue
											? infinitySymbol
											: eventData[toKey] || 0 === eventData[toKey]
											? eventData[toKey]
											: noValueSymbol}
									</strong>
									<strong className="value-item flex-grow">
										{eventData[weightKey] || 0 === eventData[weightKey]
											? eventData[weightKey]
											: noValueSymbol}
									</strong>
								</div>
							);
						})}
					</ScoreRow>
				);
			})}
		</div>
	);

	return (
		<ConfirmationModal
			size="large"
			content={content}
			title={t("common.score")}
			subTitle={scoreSetName}
			buttons={buttons}
		/>
	);
};

export default ViewScoreModal;
