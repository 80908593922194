import { AxiosError } from "axios";
import { setHasSessionExpired } from "../store/loginSlice";
import { removeStorageItem } from "./local-storage";
import { AnyAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { NavigateFunction } from "react-router-dom";
import { paths } from "../routing/router";

export type executeIfSessionActiveInterface = {
    error: AxiosError,
    dispatch: Dispatch<AnyAction>,
    navigate: NavigateFunction,
    callbackSessionExpired: Function,
    callbackSessionActive? : Function
} 


export const executeIfSessionActive = ({error, dispatch,navigate, callbackSessionExpired,callbackSessionActive}: executeIfSessionActiveInterface) => {
    const respStatusCode = error.response ? Number(error.response.status) : 0;
    if (
        !Number.isNaN(respStatusCode) &&
        (401 === respStatusCode || 403 === respStatusCode)  
    ) {
        if (callbackSessionActive) callbackSessionActive();
        removeStorageItem("auth");
        dispatch(setHasSessionExpired(true));
        navigate(paths.login)
    } else {
        callbackSessionExpired();
    }
}