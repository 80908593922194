import { languageModels } from "./languageModel";

export namespace scoreModels {
	export enum StateTypes {
		ready = "1",
		inProgress = "2",
		notCalculated = "3",
		error = "4",
	}

	export type scoreSet = {
		id: string;
		name: string;
		id_status: StateTypes;
		date: string;
		status_label: string;
		is_default: boolean;
	};

	export type scoreSetTrip = {
		id: string;
		score_level: string;
		trip_num: string;
	};

	type labelTranslated = {
  		[key in keyof typeof languageModels.languageEnum]: string;
	};

	export type statesList = {
    	value: string;
    	label: string;
    	label_translated: labelTranslated;
  	};

	export type fleet = {
		id_fleet: string;
		fleet_name: string;
	};
	export type chartDataSet = { name: string; chart_data: scoreSetTrip[] };
}