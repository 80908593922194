import { Document, Page, StyleSheet, View, Text, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import { PdfDocumentInterface } from "./PdfDocumentInterface";
import { useAppTheme } from "../../../hooks";


const getStyles = ({ theme }: Record<string, any>) =>
  StyleSheet.create({
    bold: {
      fontFamily: "Helvetica-Bold",
    },
    body: {
      color: theme.palettes.text,
      paddingTop: 39,
      paddingHorizontal: 29,
      paddingBottom: 20,
      flexGrow: 1
    },
    titleWrapper: {
      fontSize: 16,
      borderBottom: `0.5px solid ${theme.palettes.borderTertiaryDarker}`,
      marginBottom: 40,
      paddingBottom: 10,
    }
  });

const PdfDocument = ({
  chartInstance,
  title,
}: PdfDocumentInterface) => {
  const theme  = useAppTheme();
  const styles = getStyles( { theme });

  const imageScale  = 3.5;
  const pxToPtRatio = 0.24;
  const dataUrl     = chartInstance.getDataURL({
    type: 'png',
    pixelRatio: Math.ceil( imageScale ),
    backgroundColor: '#fff'
  });
  const heightPt = chartInstance.getHeight() * imageScale * pxToPtRatio;
  const widthPt  = chartInstance.getWidth() * imageScale * pxToPtRatio;

  return (
    <Document>
      <Page size='A4' orientation='landscape'>
        <View style={styles.body}>
          <View style={styles.titleWrapper}>
            <Text style={styles.bold}>
              {title}
            </Text>
          </View>
          <View>
            <Image
              src={dataUrl}
              style={{
                height: heightPt,
                width: widthPt
              }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDocument;