// Numbers are values in pixels.

export const groundPresetSizes: Record<string, any> = {
  groundButton: {
    large: 35,
    medium: 30
  },
  groundAvatar: {
    small: 36
  }
};