import classNames from "classnames";
import styled from "styled-components";
import { useAppTheme } from "../../hooks";

import "./Card.scss";


const CardStyled = styled.div`
  background: ${( props: any ) => props.theme.palettes.background};
  border-color: ${( props: any ) => props.theme.palettes.borderTertiaryLighter};
`;

const Card = ( { children, className, ...rest }: any) => {
  const theme = useAppTheme();
  
  return (
    <CardStyled
      {...rest}
      theme={theme}
      className={classNames(
        'card height-100',
        className
      )}
    >
      {children}
    </CardStyled>
  );
};

export default Card;