import classNames from "classnames";
import { Controller } from "react-hook-form";
import { TextInputInterface, } from "./TextInputInterface";

import {
  GroundTextInput,
  GroundTextInputTypes
} from "@greenvulcano/ground-react";
import { useTranslation } from "react-i18next";
import { scoreFormModels } from '../../../models/scoreFormModels';
import "./TextInput.scss";


const TextInput = ({
  name = "",
  type = "",
  valueType = scoreFormModels.valueTypeEnum.text,
  defaultValue = "",
  control,
  errors = {},
  customErrors = {},
  inputRangeErrorMessage = "",
  rules = {},
  className,
  onChangeCallback = () => null,
  ...rest
}: TextInputInterface) => {
  const { t } = useTranslation("main");
  let valueTypeRegex: any;

  if (type === GroundTextInputTypes.email) {
    rules = {
      pattern: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/i,
      ...rules,
    };
  }

  switch (valueType) {
		case scoreFormModels.valueTypeEnum.number:
			valueTypeRegex = new RegExp("^-?[0-9]*$");
			break;

		case scoreFormModels.valueTypeEnum.time:
			valueTypeRegex = new RegExp("^[0-9]{1,2}:?([0-9]{0,2})?$");
			break;

		case scoreFormModels.valueTypeEnum.hhmm:
			valueTypeRegex = new RegExp("^[0-9]{1,2}:?([0-9]{0,2})?$");
			break;

		default:
			// Left empty on purpose.
			break;
	}

  const getErrorMessage = () => {
    if (!errors[name]) return "";
    if (errors[name].message) return errors[name].message;

    if (errors[name].type === "validate") {
      return `${t("errors.enterValid")} ${valueType} ${t("common.value").toLowerCase()} ${t("common.from").toLowerCase()} (${inputRangeErrorMessage})`;
    }

    switch (valueType) {
      case scoreFormModels.valueTypeEnum.number:
        return `${t("errors.enterValid")} ${t("common.number")}`;
      case scoreFormModels.valueTypeEnum.time:
        return `${t("errors.enterValid")} ${t("common.time")}`;
        case scoreFormModels.valueTypeEnum.hhmm:
          return `${t("errors.enterValid")} ${t("common.HourMinuteFormat")}`;
      default:
        return "";
    }
  };
  const errorMessage = getErrorMessage();
  return (
    <div className={`textinput-wrapper ${errorMessage ? "tooltip" : ""}`}>
      {errorMessage && <span className="tooltip-text">{errorMessage}</span>}
      {errorMessage && <div className="tooltip-bottom"/>}
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => {
          let otherClassNames = "";

          if (type === GroundTextInputTypes.password) {
            otherClassNames = classNames({ "space-letters": value });
          }

          return (
            <GroundTextInput
              erratum={Boolean(errors[name])}
              type={type}
              className={classNames(otherClassNames, className)}
              value={value}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                let _value: string = event.target.value;

                if (
                  String(_value) &&
                  valueTypeRegex &&
                  !valueTypeRegex.test(_value)
                ) {
                  return;
                }

                if (
									[
										scoreFormModels.valueTypeEnum.time,
										scoreFormModels.valueTypeEnum.hhmm,
									].includes(valueType) &&
									_value.length > 1 &&
									!_value.includes(":")
								) {
									_value += ":";
								}

                onChange(_value);
                onChangeCallback(name);
              }}
              {...rest}
            />
          );
        }}
      />
      {/* {errors[name] ? (
        <MoodyText className='validation-error'>
          {customErrors[errors[name].type] || errors[name].message}
        </MoodyText>
      ) : null} */}
    </div>
  );
};

export default TextInput;
