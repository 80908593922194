import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppTheme, useConfirmationModal } from "../../hooks";
import { scoreModels } from "../../models/scoreModels";
import { FleetsService } from "../../services/FleetsService";

import { GroundRadio, GroundTextInput, GroundToastType, GroundToastVariant, useGroundToast } from "@greenvulcano/ground-react";
import ConfirmationModal from "../../shared-components/ConfirmationModal/ConfirmationModal";
import RequestIndicator from "../../shared-components/RequestIndicator/RequestIndicator";

import { ApplyScoreSetModalInterface } from "./ApplyScoreSetModalInterface";
import "./ApplyScoreSetModal.scss";
import { useAppDispatch } from "../../store/hooks";
import { AxiosError } from "axios";
import { executeIfSessionActive } from "../../utils/errors";


const ContentWrapper = styled.div`
  .radio-box {
    border-bottom-color: ${( props: any ) => props.theme.palettes.borderQuaternaryAlpha40};
  }
`;

const ApplyScoreSetModal = ({
  scoreSetName,
	onAction,
  navigate
}: ApplyScoreSetModalInterface) => {
  const { t } = useTranslation( 'main' );
  const theme = useAppTheme();
  const [ timer, setTimer ] = useState<ReturnType<typeof setTimeout> | null>( null );
  const [ nameFilter, setNameFilter ]             = useState( '' );
  const [ fleetName, setFleetName ] = useState( '' );
  const [ selectedFleetID, setSelectedFleetID ] = useState( '' );
  const appDispatch = useAppDispatch();
	const groundToast = useGroundToast();
	const confirmationModal = useConfirmationModal();


  const query = useQuery({
    queryKey: [ 'fleets', { nameFilter } ],
    queryFn: () => FleetsService.getFleets( nameFilter ),
    onSuccess: () => {
      setSelectedFleetID( '' );
	  },
	  onError: (error: AxiosError) => {
		
		executeIfSessionActive({
			error,
			dispatch: appDispatch,
			navigate,
			callbackSessionExpired: () =>
				groundToast.show({
					type: GroundToastType.alert,
					variant: GroundToastVariant.featured,
					message: String(error),
				}),
			callbackSessionActive : () => confirmationModal.close()
		});
	}

  })

  useEffect(() => {
    if ( timer ) {
      clearTimeout( timer );
    }

		setTimer( setTimeout(() => setNameFilter( fleetName ), 500 ) );

    return () => {
      if ( timer ) {
        clearTimeout( timer );
      }
    };
  }, [ fleetName ] );
  
  const content = (
		<ContentWrapper
			theme={theme}
			className="apply-score-set-modal-content flex flex-columns gap-1"
		>
			<div className="filter-box">
				<h4>{t("common.fleet")}</h4>
				<GroundTextInput
					fluid
					height="48"
					placeholder={t("common.findAFleet") as string}
					value={fleetName}
					onChange={(event: any) => setFleetName(event.target.value)}
				/>
			</div>
			{query.isSuccess ? (
				<>
					{!!query.data.length && (
						<div className="radios-wrapper">
							{query.data.map((item: scoreModels.fleet, idx: number) => {
								const uid = item.id_fleet + "_" + idx;
								return (
									<div
										key={"fleetBox_" + uid}
										className="radio-box flex flex-vcenter"
									>
										<div>
											<GroundRadio
												id={"fleet-" + uid}
												color="secondary"
												name="fleet"
												value={item.id_fleet}
												onChange={(event: any) =>
													setSelectedFleetID(event.target.value)
												}
											/>
										</div>
										<span className="label">
											{item.id_fleet + " " + item.fleet_name}
										</span>
									</div>
								);
							})}
						</div>
					)}
          {!query.data.length && <div className="fleet-not-found"> <h2>{t('modals.noFleetsFound')}</h2> <p>{ t('modals.noFleetsFoundMessage',{nameFilter})}</p></div>}
				</>
			) : (
				<RequestIndicator
					isLoading={!query.isError}
					spinnerOptions={{ scale: 0.5 }}
				/>
			)}
		</ContentWrapper>
	);

  return (
    <ConfirmationModal
      size='medium'
      content={content}
      title={t( 'common.applyThisSet', { scoreSetName } )}
      actionDisabled={!Boolean( selectedFleetID )}
      onAction={() => {
        onAction( selectedFleetID );

        return true;
      }}
    />
  );
};

export default ApplyScoreSetModal;