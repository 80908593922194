import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useAppTheme } from "../../hooks";

import { GroundLoadingSpinner } from "@greenvulcano/ground-react";
import MoodyText from "../MoodyText/MoodyText";

import { RequestIndicatorInterface } from "./RequestIndicatorInterface"
import "./RequestIndicator.scss";


const RequestIndicator = ({
  isLoading,
  hideError,
  errorMessage,
  delay,
  spinnerOptions,
  backgroundColor = 'none',
  className
}: RequestIndicatorInterface ) => {
  const { t } = useTranslation( 'main' );
  const theme = useAppTheme();
  const [ canBeDisplayed, setCanBeDisplayed ] = useState( !delay );

  const bgColorValue = ( 
    'none' !== backgroundColor ? 
    theme.palettes[backgroundColor] : 
    'transparent'
  );
  
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;

    if ( delay ) {
      timer = setTimeout(() => setCanBeDisplayed( true ), delay );
    }

    return () => {
      if ( timer ) {
        clearTimeout( timer );
      }
    };
  }, [] );

  return (
    <div
      className={classNames(
        'request-indicator-wrapper height-100 width-100 flex flex-center',
        className
      )}
      style={{ background: bgColorValue }}
      >
      { canBeDisplayed ? ( isLoading ? (
        <GroundLoadingSpinner {...spinnerOptions} />
      ): ( hideError ? null : (
        <div>
          <h3>
            <MoodyText color='textLight'>
              {t( 'errors.requestFailed' )}
            </MoodyText>
          </h3>
          {errorMessage ? (
            <p>
              <MoodyText
                color='textLight'
                className='error-details'
              >
                {errorMessage}
              </MoodyText>
            </p>
          ) : null}
        </div>
      ))) : null }
    </div>
  );
};

export default RequestIndicator;