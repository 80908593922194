import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppTheme, useConfirmationModal } from "../../hooks";
import { getAppConfig } from "../../config";
import { applyErrorBoundary } from "../../utils/error-boundary";
import { isObjectEmpty, capitalize, convertToSnakeCase } from "../../utils";
import { attemptTranslation } from "../../utils/i18n";

import { scoreFormModels } from "../../models/scoreFormModels";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { selectFunctionality } from "../../store/functionalitiesSlice";
import * as ScoreSetService from "../../services/ScoreSetService";
import {
  selectScoreSetToLoad,
  markScoreSetAsLoaded,
  ScoreSetToLoadStates,
  setIsEditModeActive,
  getIsEditModeActive,
  getShouldReset,
  setShouldReset,
} from "../../store/scoreFormSlice";

import {
  GroundButtonTypes,
  GroundButtonVariants,
  GroundIcon,
  GroundToastType,
  GroundToastVariant,
  useGroundToast,
} from "@greenvulcano/ground-react";
import Card from "../../shared-components/Card/Card";
import CustomButton from "../../shared-components/CustomButton/CustomButton";
import Checkbox from "../../shared-components/RHF-input-wrappers/Checkbox/Checkbox";
import TextInput from "../../shared-components/RHF-input-wrappers/TextInput/TextInput";
import SaveScoreSetModal from "../SaveScoreSetModal/SaveScoreSetModal";

import "./ScoreForm.scss";
import {
  compareTimes,
  convertTimeToMinutes,
  defaultMaxTime,
  defaultMinTime,
  hoursInOneDay,
  subtractMinute,
  validateNumber,
  validateTime,
} from "../../utils/date";
import { areObjectsEqual } from "../../utils/values";
import { infinityValue, infinitySymbol } from "../../utils/constant";
import { queryClient } from "../../App";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { executeIfSessionActive } from "../../utils/errors";

const getFieldName = (
  eventName: string,
  severityID: scoreFormModels.SeverityIdEnum,
  fieldID: string
) => {
  return (
    eventName +
    "-" +
    severityID +
    ("weight" === fieldID ? "Severity" : "SeverityValue") +
    capitalize(fieldID)
  );
};

const parseFieldName = (fieldName: string) => {
  let fieldID = scoreFormModels.FieldIdEnum.empty;
  let severityID = scoreFormModels.SeverityIdEnum.empty;

  const [eventName, part2] = fieldName.split("-");

  if (part2.includes("SeverityValue")) {
    const [_sevID, _fieldID] = part2.split("SeverityValue");

    fieldID = _fieldID.toLowerCase() as scoreFormModels.FieldIdEnum;
    severityID = _sevID.toLowerCase() as scoreFormModels.SeverityIdEnum;
  } else if (part2.includes("Severity")) {
    const [_sevID, _fieldID] = part2.split("Severity");

    fieldID = _fieldID.toLowerCase() as scoreFormModels.FieldIdEnum;
    severityID = _sevID.toLowerCase() as scoreFormModels.SeverityIdEnum;
  } else {
    fieldID = part2 as scoreFormModels.FieldIdEnum;
  }

  return { eventName, severityID, fieldID };
};

const prepareDataForRequest = (
  data: Record<string, any>,
  eventTypes: scoreFormModels.eventType[],
  currentSeverityCompletionState: scoreFormModels.currentSeverityCompletionStateType,
  disabledEventTypes: string[]
) => {
  const _map: Record<string, any> = {};

  for (const key in data) {
    let { eventName, severityID, fieldID } = parseFieldName(key);

    const eventType = eventTypes.find(
      (item: scoreFormModels.eventType) => item.name === eventName
    );

    if (!_map[eventName]) {
      _map[eventName] = {
        id_event_type: eventType && eventType.id ? eventType.id : 0,
      };
    }

    if (severityID) {
      const entryKey = `${severityID}_severity_value_${fieldID}`;

      const { low, medium, high } = currentSeverityCompletionState[eventName];

      const isComplete = (severity: { [key: string]: string | boolean }) =>
        !!severity?.isComplete;

      const shouldUseInfinityValue =
        scoreFormModels.FieldIdEnum.to === fieldID &&
        ((scoreFormModels.SeverityIdEnum.high === severityID &&
          isComplete(low) &&
          isComplete(medium) &&
          isComplete(high)) ||
          (scoreFormModels.SeverityIdEnum.medium === severityID &&
            isComplete(low) &&
            isComplete(medium) &&
            !isComplete(high)) ||
          (scoreFormModels.SeverityIdEnum.low === severityID &&
            isComplete(low) &&
            !isComplete(medium) &&
            !isComplete(high)));

      _map[eventName][entryKey] = shouldUseInfinityValue
        ? infinityValue
        : String(data[key]) || null;
    } else {
      fieldID = convertToSnakeCase(fieldID) as scoreFormModels.FieldIdEnum;
      _map[eventName][fieldID] = String(
        typeof data[key] === "boolean" ? Number(data[key]) : data[key]
      );
    }
  }

  const result = Object.entries(_map).reduce<scoreFormModels.scoreSetEvent[]>(
    (acc, [key, item]) => {
      if (
        !disabledEventTypes.includes(key) &&
        (!!item.low_severity_value_weight ||
          !!item.medium_severity_value_weight ||
          item.high_severity_value_weight)
      ) {
        acc.push(item);
      }
      return acc;
    },
    []
  );

  return result;
};

const TitleWrapper = styled.div`
  .severity-titles {
    color: ${(props: any) => props.theme.palettes.textLight};
  }
`;
const ScoreSetNamesWrapper = styled.div`
  max-height: ${() => "36px"};
  overflow: ${() => "hidden"};
  text-overflow: ${() => "ellipsis"};
  white-space: ${() => "nowrap"};
`;

const HeadingsWrapper = styled.div`
  color: ${(props: any) => props.theme.palettes.textLight};
  border-bottom-color: ${(props: any) =>
    props.theme.palettes.borderQuaternaryAlpha60};
`;

const ScoreRow = styled.div`
  border-bottom-color: ${(props: any) =>
    props.theme.palettes.borderQuaternaryAlpha60};

  &:hover {
    background-color: ${(props: any) => props.theme.palettes.secondaryAlpha10};
  }

  .controls-set-item {
    border-left-color: ${(props: any) => props.theme.palettes.borderQuaternary};
  }
`;

const ScoreForm = () => {
  const { t } = useTranslation("main");
  const theme = useAppTheme();
  const groundToast = useGroundToast();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const confirmationModal = useConfirmationModal();
	const disabledEventTypes: string[] = useAppSelector(selectFunctionality("disabledEventTypes"));
  const eventTypes = useAppSelector(
    selectFunctionality("eventTypes")
  ) as scoreFormModels.eventType[];
  const [arraysOfCompletedSeverities, setArraysOfCompletedSeverities] =
    useState<{ [key: string]: scoreFormModels.SeverityIdEnum[] }>({});
  const [
    allSeveritiesForEnabledEventsAreComplete,
    setAllSeveritiesForEnabledEventsAreComplete,
  ] = useState(false);
  const [currentSeverityCompletionState, setCurrentSeverityCompletionState] =
    useState<scoreFormModels.currentSeverityCompletionStateType>({});
  const [launchCalc, setLaunchCalc] = useState(false);
  const defaultScoreSetName = t("common.newScore");
  const [scoreSetName, setScoreSetName] = useState(defaultScoreSetName);

  const {
    status: scoreSetToLoadStatus,
    entries: scoreSetToLoadData,
    name: scoreSetToLoadName,
    id: scoreSetToLoadId,
  } = useAppSelector(selectScoreSetToLoad);
  const isEditModeActive = useAppSelector(getIsEditModeActive);
  const shouldReset = useAppSelector(getShouldReset);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm<Record<string, any>>({
    mode: "onChange",
  });

  const getValuesWatch = watch();
  const maxWeight = getAppConfig("maxWeight");
  const fromToAndWeight = [
    scoreFormModels.FieldIdEnum.from,
    scoreFormModels.FieldIdEnum.to,
    scoreFormModels.FieldIdEnum.weight,
  ];
  const severityLevels = [
    scoreFormModels.SeverityIdEnum.low,
    scoreFormModels.SeverityIdEnum.medium,
    scoreFormModels.SeverityIdEnum.high,
  ];

  const resetFormAndName = useCallback(() => {
    reset({});
    setScoreSetName(defaultScoreSetName);
  }, [reset]);

  const resetAll = () => {
    appDispatch(setIsEditModeActive(false));
    setArraysOfCompletedSeverities({});
    setAllSeveritiesForEnabledEventsAreComplete(false);
    setCurrentSeverityCompletionState({});
    resetFormAndName();
  };
  // ! This setTimeout function is essential for invalidating the query.
  // ! It's used because the back-end sets the state to "In progress" only after
  // ! the calculation has started.Currently, we lack a mechanism to detect when
  // !this change occurs.
  const invalidateScoreSetListQueryWithDelay = () => {
    setTimeout(() => {
      queryClient.invalidateQueries(["scoreSetList"]);
    }, 200);
  };
  const calcMutation = useMutation({
    mutationFn: (scoreSetId: string | number) => {
      groundToast.show({
        type: GroundToastType.info,
        variant: GroundToastVariant.featured,
        message: `${t("toastMessages.calculationStarted")} `,
      });
      return ScoreSetService.calculateScoreSet(scoreSetId);
    },
    onSuccess: () => {
      setLaunchCalc(false);

      queryClient.invalidateQueries(["scoreSetList"]);
      queryClient.invalidateQueries(["tripsDistributionFiveMinutes"]);
      queryClient.invalidateQueries(["tripsDistributionOneSecond"]);
      groundToast.show({
        type: GroundToastType.success,
        variant: GroundToastVariant.featured,
        message: `${t("toastMessages.calculationSuccessful")} `,
      });
    },
    onError: (error : AxiosError) => {
      setLaunchCalc(false);

      queryClient.invalidateQueries(["scoreSetList"]);
      executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
					}),
			});
   
    },
  });

  const saveScoreSetMutation = useMutation({
    mutationFn: ScoreSetService.saveScoreSet,
    onSuccess: (scoreSetID: string) => {
      invalidateScoreSetListQueryWithDelay();
      resetAll();
      confirmationModal.close();

      groundToast.show({
        type: GroundToastType.success,
        variant: GroundToastVariant.featured,
        message: t("toastMessages.scoreSetSuccessfullySaved"),
      });

      if (scoreSetID) {
        if (launchCalc) {
          calcMutation.mutate(scoreSetID);
        }
      }
    },
    onError: (error : AxiosError) => {
      queryClient.invalidateQueries(["scoreSetList"]);
      confirmationModal.close();
      executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
					}),
			});
    },
  });

  const editScoreSetMutation = useMutation({
    mutationFn: ScoreSetService.editScoreSet,
    onSuccess: (scoreSetID: string) => {
      invalidateScoreSetListQueryWithDelay();

      resetAll();
      confirmationModal.close();

      groundToast.show({
        type: GroundToastType.success,
        variant: GroundToastVariant.featured,
        message: t("toastMessages.scoreSetSuccessfullyEdited"),
      });

      if (scoreSetID) {
        if (launchCalc) {
          calcMutation.mutate(scoreSetID);
        }
      }
    },
    onError: (error : AxiosError) => {
      queryClient.invalidateQueries(["scoreSetList"]);
      confirmationModal.close();
      executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
					}),
			});
    },
  });

  const onSaveAction = (scoreSetName: string, data: Record<string, any>) => {
    const score = prepareDataForRequest(
      data,
      eventTypes,
      currentSeverityCompletionState,
      disabledEventTypes
    );
    const payload: any = { score };
    if (isEditModeActive) {
      const scoreSetNameHasChanged =
        scoreSetName.trim().toLocaleLowerCase() !==
        scoreSetToLoadName?.trim().toLocaleLowerCase();
      if (scoreSetNameHasChanged) payload.name = scoreSetName;
      payload.id_score_set =
        typeof scoreSetToLoadId === "number"
          ? scoreSetToLoadId.toString()
          : scoreSetToLoadId;
      editScoreSetMutation.mutate(payload);
    } else {
      payload.name = scoreSetName;
      saveScoreSetMutation.mutate(payload);
    }
  };

  const onSubmitHandler: SubmitHandler<Record<string, any>> = (data) => {
    confirmationModal.open(
      <SaveScoreSetModal
        navigate={navigate}
        title={t("common.saveSet")}
        onAction={(scoreSetName: string) => onSaveAction(scoreSetName, data)}
        scoreSetName={scoreSetName}
      />
    );
  };

  const onEditScoreSetNameHandler = () => {
    confirmationModal.open(
      <SaveScoreSetModal
        navigate={navigate}
        title={t("common.editScoreName")}
        onAction={(scoreSetName: string) => {
          setScoreSetName(scoreSetName);
          confirmationModal.close();
        }}
        scoreSetName={scoreSetName}
      />
    );
  };
  const checkAndSetError = (
    condition: boolean,
    name: string,
    error: string
  ) => {
    if (condition) {
      setError(name, {
        message: t(error) ?? "",
      });
    } else {
      clearErrors(name);
    }
  };
  const onChangeWeightHandler = useCallback((fieldName: string) => {
    const { eventName, severityID } = parseFieldName(fieldName);
    const { low, medium, high } = scoreFormModels.SeverityIdEnum;
    const { weight, from, to } = scoreFormModels.FieldIdEnum;

    const lowToName = getFieldName(eventName, low, to);
    const mediumToName = getFieldName(eventName, medium, to);

    const lowWeightName = getFieldName(eventName, low, weight);
    const lowWeightValue = parseInt(getValues(lowWeightName));
    const mediumWeightName = getFieldName(eventName, medium, weight);
    const mediumWeightValue = parseInt(getValues(mediumWeightName));
    const highWeightName = getFieldName(eventName, high, weight);
    const highWeightValue = parseInt(getValues(highWeightName));

    const lowFromName = getFieldName(eventName, low, from);
    const lowFromValue = getValues(lowFromName);
    const mediumFromName = getFieldName(eventName, medium, from);
    const mediumFromValue = getValues(mediumFromName);
    const highFromName = getFieldName(eventName, high, from);

    severityLevels.forEach((severity) => {
      if (severity === low && severityID === low) {
        if (!lowWeightValue && lowWeightValue !== 0) {
          if (!checkIfValueIsDefined(lowFromValue)) {
            setValue(mediumWeightName, "");
            setValue(highWeightName, "");
            setValue(mediumFromName, "");
            setValue(highFromName, "");
            setValue(mediumToName, "");
          }
        }
        checkAndSetError(
          !isNaN(mediumWeightValue) && mediumWeightValue <= lowWeightValue,
          mediumWeightName,
          "errors.weightTooLow"
        );
      }
      if (severity === medium) {
        if (!mediumWeightValue && mediumWeightValue !== 0) {
          if (!checkIfValueIsDefined(mediumFromValue)) {
            setValue(highWeightName, "");
            setValue(highFromName, "");
          }
          setValue(mediumToName, "");
          setValue(lowToName, "");
        }
        checkAndSetError(
          !isNaN(lowWeightValue) && mediumWeightValue <= lowWeightValue,
          lowWeightName,
          "errors.weightTooHigh"
        );
        checkAndSetError(
          !isNaN(highWeightValue) && highWeightValue <= mediumWeightValue,
          highWeightName,
          "errors.weightTooLow"
        );
      }
      if (severity === high && severityID === high) {
        if (!highWeightValue && highWeightValue !== 0) {
          setValue(mediumToName, "");
        }
        checkAndSetError(
          !isNaN(highWeightValue) && highWeightValue <= mediumWeightValue,
          mediumWeightName,
          "errors.weightTooHigh"
        );
      }
    });
  }, []);

  const checkIfValueIsDefined = (value: any) => {
    return value && value !== 0;
  };
  const onChangeFromHandler = useCallback(
    (fieldName: string, eventType: scoreFormModels.eventType) => {
      const { eventName, severityID } = parseFieldName(fieldName);
      const { low, medium, high } = scoreFormModels.SeverityIdEnum;
      const { from, to, weight } = scoreFormModels.FieldIdEnum;

      const lowToName = getFieldName(eventName, low, to);
      const mediumToName = getFieldName(eventName, medium, to);

      const lowWeightName = getFieldName(eventName, low, weight);
      const lowWeightValue = parseInt(getValues(lowWeightName));
      const mediumWeightName = getFieldName(eventName, medium, weight);
      const mediumWeightValue = parseInt(getValues(mediumWeightName));
      const highWeightName = getFieldName(eventName, high, weight);

      const lowFromName = getFieldName(eventName, low, from);
      const lowFromValue = getValues(lowFromName);
      const mediumFromName = getFieldName(eventName, medium, from);
      const mediumFromValue = getValues(mediumFromName);
      const highFromName = getFieldName(eventName, high, from);
      const highFromValue = getValues(highFromName);

      const valuesAreInvalid = (
        valueA: string,
        valueB: string,
        eventType: scoreFormModels.eventType
      ) => {
        switch (eventType.unit) {
          case scoreFormModels.valueTypeEnum.time:
            return compareTimes(
              valueA,
              valueB,
              eventType.min_value as string,
              eventType.max_value as string
            );
          case scoreFormModels.valueTypeEnum.hhmm:
            const hhmmA = convertTimeToMinutes(valueA);
            const hhmmB = convertTimeToMinutes(valueB);
            return hhmmA >= hhmmB;
          default:
            return Number(valueA) >= Number(valueB);
        }
      };
      const updatePrevToField = (
        currentValue: string,
        eventType: scoreFormModels.eventType,
        fieldNameToUpdate: string
      ) => {
        switch (eventType.unit) {
          case scoreFormModels.valueTypeEnum.hhmm:
            if (validateTime({time : currentValue, minTime : eventType.min_value ?? defaultMinTime, maxTime : eventType.max_value ?? defaultMaxTime,maxHoursLimit :hoursInOneDay})) {
              setValue(fieldNameToUpdate, subtractMinute(currentValue));
            }
            break;
          case scoreFormModels.valueTypeEnum.time:
            if (validateTime({time : currentValue, minTime : eventType.min_value ?? defaultMinTime, maxTime : eventType.max_value ?? defaultMaxTime})) {
              setValue(fieldNameToUpdate, subtractMinute(currentValue));
            }
            break;
          default:
            setValue(fieldNameToUpdate, Number(currentValue) - 1);
            break;
        }
      };

      severityLevels.forEach((severity) => {
        if (severity === low && severityID === low) {
          if (!lowFromValue && lowFromValue !== 0) {
            if (!checkIfValueIsDefined(lowWeightValue)) {
              setValue(mediumFromName, "");
              setValue(highFromName, "");
              setValue(mediumWeightName, "");
              setValue(highWeightName, "");
              setValue(mediumToName, "");
            }
            setValue(lowToName, "");
          }
          checkAndSetError(
            checkIfValueIsDefined(mediumFromValue) &&
              valuesAreInvalid(lowFromValue, mediumFromValue, eventType),
            mediumFromName,
            "errors.valueTooLow"
          );
        }
        if (severity === medium) {
          if (!mediumFromValue && mediumFromValue !== 0) {
            if (!checkIfValueIsDefined(mediumWeightValue)) {
              setValue(highFromName, "");
              setValue(highWeightName, "");
            }
            setValue(mediumToName, "");
            setValue(lowToName, "");
          }
          checkAndSetError(
            checkIfValueIsDefined(lowFromValue) &&
              checkIfValueIsDefined(mediumFromValue) &&
              valuesAreInvalid(lowFromValue, mediumFromValue, eventType),
            lowFromName,
            "errors.valueTooHigh"
          );
          checkAndSetError(
            checkIfValueIsDefined(highFromValue) &&
              valuesAreInvalid(mediumFromValue, highFromValue, eventType),
            highFromName,
            "errors.valueTooLow"
          );
          if (mediumFromValue && severityID === medium) {
            updatePrevToField(mediumFromValue, eventType, lowToName);
          }
        }
        if (severity === high && severityID === high) {
          if (!highFromValue && highFromValue !== 0) {
            setValue(mediumToName, "");
          }
          checkAndSetError(
            checkIfValueIsDefined(mediumFromValue) &&
              checkIfValueIsDefined(highFromValue) &&
              valuesAreInvalid(mediumFromValue, highFromValue, eventType),
            mediumFromName,
            "errors.valueTooHigh"
          );
          if (highFromValue) {
            updatePrevToField(highFromValue, eventType, mediumToName);
          }
        }
      });
    },
    []
  );

  const disableInput = (
    item: scoreFormModels.eventType,
    severityID: scoreFormModels.SeverityIdEnum
  ) => {
    if (item.disabled) return true;

    if (severityID === scoreFormModels.SeverityIdEnum.medium) {
      return !arraysOfCompletedSeverities[item.name]?.includes(
        scoreFormModels.SeverityIdEnum.low
      );
    }
    if (severityID === scoreFormModels.SeverityIdEnum.high) {
      return !arraysOfCompletedSeverities[item.name]?.includes(
        scoreFormModels.SeverityIdEnum.medium
      );
    }
  };

  const disableSubmit = () => {
    // Get all the current form values
    const values: Record<string, any> = getValues();

    // If there are any errors, return true to disable the submit button
    if (!isObjectEmpty(errors)) {
      return true;
    }

    // Flag to track if at least one severity level for any event type is filled
    let atLeastOneSeverityForOneEventIsFilled = false;

    // Iterate over each event type
    for (const item of eventTypes) {
      // Initialize a counter for the number of filled severity levels for the current event type
      let filledSeverityCount = 0;

      // Iterate over each severity level
      for (const severityID of severityLevels) {
        // Get the field names for 'from' and 'weight' for the current event type and severity level
        const fromFieldName = getFieldName(item.name, severityID, "from");
        const weightFieldName = getFieldName(item.name, severityID, "weight");

        // If neither 'from' nor 'weight' is filled for the current event type and severity level, skip to the next severity level
        if (!values[fromFieldName] && !values[weightFieldName]) {
          continue;
        }

        // If either 'from' or 'weight' is not filled for the current event type and severity level, return true to disable the submit button
        if (
          (!values[fromFieldName] || !values[weightFieldName]) &&
          !item.severityCanBeIncomplete
        ) {
          return true;
        }

        // If both 'from' and 'weight' are filled for the current event type and severity level, increment the filled severity counter
        filledSeverityCount += 1;
      }

      // If at least one severity level for the current event type is filled, set the flag to true
      atLeastOneSeverityForOneEventIsFilled =
        filledSeverityCount > 0 || atLeastOneSeverityForOneEventIsFilled;
    }

    // If no severity level for any event type is filled, return true to disable the submit button
    return (
      !atLeastOneSeverityForOneEventIsFilled ||
      !allSeveritiesForEnabledEventsAreComplete
    );
  };

  const getPlaceholder = (
    unit: scoreFormModels.valueTypeEnum | undefined,
    unitDescr: string | undefined
  ) => {
    let result = "";
    if (scoreFormModels.valueTypeEnum.time === unit) {
      result = "00:00";
    } else if (scoreFormModels.valueTypeEnum.hhmm === unit) {
      result = "hh:mm";
    } else {
      result = unitDescr ? (t(unitDescr) as string) : result;
    }
    return result;
  };
  useEffect(() => {
    const currentValues = getValues();
    const CurrentSeverityCompletionStateTemp: scoreFormModels.currentSeverityCompletionStateType =
      {};
    Object.entries(currentValues).forEach(([key, value]) => {
      let { eventName, severityID, fieldID } = parseFieldName(key);
      if (!CurrentSeverityCompletionStateTemp[eventName]) {
        CurrentSeverityCompletionStateTemp[eventName] = {};
      } else {
        if (!CurrentSeverityCompletionStateTemp[eventName][severityID]) {
          CurrentSeverityCompletionStateTemp[eventName][severityID] = {
            [fieldID]: value,
          };
        } else {
          CurrentSeverityCompletionStateTemp[eventName][severityID][fieldID] =
            value;
          CurrentSeverityCompletionStateTemp[eventName][severityID].isComplete =
            CurrentSeverityCompletionStateTemp[eventName][severityID].from !==
              "" &&
            CurrentSeverityCompletionStateTemp[eventName][severityID].weight !==
              "";
        }
      }
    });
    if (
      !areObjectsEqual(
        currentSeverityCompletionState,
        CurrentSeverityCompletionStateTemp
      )
    ) {
      setCurrentSeverityCompletionState(CurrentSeverityCompletionStateTemp);
      const arraysOfCompletedSeveritiesTemp: {
        [key: string]: scoreFormModels.SeverityIdEnum[];
      } = {};

      Object.entries(CurrentSeverityCompletionStateTemp).forEach(
        ([eventName, severityGroup]) => {
          if (!arraysOfCompletedSeveritiesTemp[eventName]) {
            arraysOfCompletedSeveritiesTemp[eventName] = [];
          }

          Object.entries(severityGroup).map(([severityKey, severityValues]) => {
            if (
              severityLevels.includes(
                severityKey as scoreFormModels.SeverityIdEnum
              )
            ) {
              if (severityValues.isComplete) {
                arraysOfCompletedSeveritiesTemp[eventName].push(
                  severityKey as scoreFormModels.SeverityIdEnum
                );
              }
            }
          });
        }
      );
      setArraysOfCompletedSeverities(arraysOfCompletedSeveritiesTemp);
      let allSeveritiesForEnabledEventsAreCompleteTemp = true;
      Object.entries(arraysOfCompletedSeveritiesTemp).forEach(
        ([key, array]) => {
          if (array.length && !disabledEventTypes.includes(key)) {
            const includesLow = array.includes(
              scoreFormModels.SeverityIdEnum.low
            );
            const includesMedium = array.includes(
              scoreFormModels.SeverityIdEnum.medium
            );
            const includesHigh = array.includes(
              scoreFormModels.SeverityIdEnum.high
            );

            allSeveritiesForEnabledEventsAreCompleteTemp =
              includesLow &&
              ((includesMedium && includesHigh) ||
                (includesMedium && !includesHigh) ||
                (!includesMedium && !includesHigh)) &&
              allSeveritiesForEnabledEventsAreCompleteTemp;
          }
        }
      );
      setAllSeveritiesForEnabledEventsAreComplete(
        allSeveritiesForEnabledEventsAreCompleteTemp
      );
    }
  }, [getValuesWatch]);

  useEffect(() => {
    if (shouldReset) {
      resetFormAndName();
      appDispatch(setShouldReset(false));
    }
  }, [appDispatch, resetFormAndName, shouldReset]);

  useEffect(() => {
    //using trigger to force error validation after the  appDispatch(markScoreSetAsLoaded()); is called at the end of this useEffect
    if (scoreSetToLoadStatus === ScoreSetToLoadStates.loaded) {
      trigger();
    }
    if (scoreSetToLoadStatus !== ScoreSetToLoadStates.awaiting) {
      return;
    }
    const newValues: Record<string, string | number | boolean> = {};
    if (scoreSetToLoadName) setScoreSetName(scoreSetToLoadName);
    scoreSetToLoadData.forEach((data: scoreFormModels.scoreSetEvent) => {
      const eventType = eventTypes.find(
        (item) => String(item.id) === String(data.idEventType)
      );
      if (eventType !== undefined) {
        for (const key in data) {
          const fieldName = eventType.name + "-" + key;
          const { fieldID } = parseFieldName(fieldName);
          const dataValue = data[key as keyof scoreFormModels.scoreSetEvent];
          if ("isActive" === key && Boolean(Number(dataValue))) {
            newValues[fieldName] =
              Boolean(Number(dataValue)) && !eventType.disabled;
          } else if (fromToAndWeight.includes(fieldID)) {
            newValues[fieldName] = dataValue ?? "";
          }
        }
        const fromHighFieldName = getFieldName(
          eventType.name,
          scoreFormModels.SeverityIdEnum.high,
          scoreFormModels.FieldIdEnum.from
        );
        const weightHighFieldName = getFieldName(
          eventType.name,
          scoreFormModels.SeverityIdEnum.high,
          scoreFormModels.FieldIdEnum.weight
        );

        onChangeFromHandler(fromHighFieldName, eventType);
        onChangeWeightHandler(weightHighFieldName);
        trigger([fromHighFieldName, weightHighFieldName]);
      }
    });
    reset(newValues, { keepDefaultValues: true });
  
    appDispatch(markScoreSetAsLoaded());
  }, [scoreSetToLoadStatus]);

  return (
    <Card className="new-score-container">
      <form
        className="height-100 flex flex-columns gap-3"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <TitleWrapper theme={theme} className="title-wrapper flex">
          <div className="page-title">
            <h3 title={scoreSetName} className="flex flex-vcenter gap-2">
              <ScoreSetNamesWrapper>
                {scoreSetName.length
                  ? scoreSetName
                  : t("common.scoreSetNameNotDefined")}
              </ScoreSetNamesWrapper>
              <div className="clickable" onClick={onEditScoreSetNameHandler}>
                <GroundIcon color="secondary" svgIcon="edit" width="20" />
              </div>
            </h3>
          </div>
          <div className="severity-titles flex-grow flex">
            <h3 className="flex-grow">{t("common.severityLow")}</h3>
            <h3 className="flex-grow">{t("common.severityMedium")}</h3>
            <h3 className="flex-grow">{t("common.severityHigh")}</h3>
          </div>
        </TitleWrapper>
        <div className="flex-grow">
          <HeadingsWrapper theme={theme} className="headings-wrapper flex">
            <h4 className="event-label-item">{t("common.riskEvent")}</h4>
            <h4 className="active-checkbox-item">{t("common.active")}</h4>
            {severityLevels.map((severityID: any) => {
              return (
                <div
                  key={"severityHeadings_" + severityID}
                  className="controls-set-item flex-grow flex"
                >
                  <h4 className="flex-grow">{t("common.from")}</h4>
                  <h4 className="flex-grow">{t("common.to")}</h4>
                  <h4 className="flex-grow">{t("common.weight")}</h4>
                </div>
              );
            })}
          </HeadingsWrapper>
          {eventTypes.map((item: scoreFormModels.eventType) => {
            return (
              <ScoreRow
                key={"row_" + item.name}
                theme={theme}
                className={`score-row flex ${item.disabled ? "disabled" : ""}`}
              >
                <div className="event-label-item flex flex-vcenter">
                  <div className="flex flex-bottom gap-1/2">
                    <strong className={item.name + "-label"}>
                      {attemptTranslation(item.name, t)}
                    </strong>
                    {item.unitDescr ? (
                      <span className="text-xsmall">({t(item.unitDescr)})</span>
                    ) : null}
                  </div>
                </div>
                <div className="active-checkbox-item controls-set-item flex flex-center">
                  <Checkbox
                    color="secondary"
                    name={item.name + "-isActive"}
                    control={control}
                    errors={errors}
                    disabled={item.disabled}
                  />
                </div>
                {severityLevels.map(
                  (severityID: scoreFormModels.SeverityIdEnum, idx: number) => {
                    return (
                      <div
                        key={item.name + "_" + severityID + "_" + idx}
                        className="controls-set-item flex-grow flex gap-1"
                      >
                        <TextInput
                          fluid
                          valueType={
                            item.unit
                              ? item.unit
                              : scoreFormModels.valueTypeEnum.number
                          }
                          name={getFieldName(
                            item.name,
                            severityID,
                            scoreFormModels.FieldIdEnum.from
                          )}
                          disabled={disableInput(item, severityID)}
                          placeholder={getPlaceholder(
                            item.unit,
                            item.unitDescr
                          )}
                          height={34}
                          control={control}
                          errors={errors}
                          inputRangeErrorMessage={item.min_value === null && item.max_value === null
                            ? "-∞ to ∞"
                            : `${item.min_value === null ? "-∞" : item.min_value} ${t("common.to").toLowerCase()} ${
                                item.max_value === null ? "∞" : item.max_value
                              }`}
                          rules={{
                            validate: (value: string) => {
                              if (item.unit === undefined || !value)
                                return true;
                                if (
                                  scoreFormModels.valueTypeEnum.number === item.unit
                                ) {
                                  
                                  return validateNumber(
                                    value,
                                    item.min_value,
                                    item.max_value
                                  );
                                }
                              if (
                                [
                                  scoreFormModels.valueTypeEnum.time,
                                ].includes(item.unit)
                              ) {
                                return validateTime(
                                {  time : value,
                                 minTime: item.min_value ?? defaultMinTime,
                                 maxTime: item.max_value ?? defaultMaxTime,
                                 maxHoursLimit:  hoursInOneDay
                                }
                                );
                              }
                              if(
                                [
                                  scoreFormModels.valueTypeEnum.hhmm,
                                ].includes(item.unit)
                              ) {
                                return validateTime(
                                  {  time : value,
                                    minTime: item.min_value ?? defaultMinTime,
                                    maxTime: item.max_value ?? defaultMaxTime,
                                   }
                                );
                              } 
                            },
                          }}
                          onChangeCallback={(fieldName: string) => {
                            return onChangeFromHandler(fieldName, item);
                          }}
                        />
                        <TextInput
                          fluid
                          disabled
                          valueType={
                            item.unit
                              ? item.unit
                              : scoreFormModels.valueTypeEnum.number
                          }
                          name={getFieldName(
                            item.name,
                            severityID,
                            scoreFormModels.FieldIdEnum.to
                          )}
                          placeholder={infinitySymbol}
                          height={34}
                          control={control}
                          errors={errors}
                        />
                        <TextInput
                          fluid
                          disabled={disableInput(item, severityID)}
                          valueType={scoreFormModels.valueTypeEnum.number}
                          name={getFieldName(
                            item.name,
                            severityID,
                            scoreFormModels.FieldIdEnum.weight
                          )}
                          placeholder={t("common.weight") as string}
                          height={34}
                          control={control}
                          errors={errors}
                          rules={{
                            validate: (value: string) => {
                              if (!String(value)) {
                                return true;
                              }

                              const maxValue =
                                maxWeight - (severityLevels.length - 1) + idx;

                              return (
                                Number(value) > 0 && Number(value) <= maxValue
                              );
                            },
                          }}
                          onChangeCallback={onChangeWeightHandler}
                        />
                      </div>
                    );
                  }
                )}
              </ScoreRow>
            );
          })}
        </div>
        <div className="flex flex-hcenter gap-2">
          <CustomButton
            variant={GroundButtonVariants.ghost}
            type={GroundButtonTypes.reset}
            text={t("common.newScore") as string}
            onClick={() => resetAll()}
          />
          <CustomButton
            disabled={disableSubmit()}
            variant={GroundButtonVariants.ghost}
            type={GroundButtonTypes.submit}
            text={t("common.saveAndCalculate") as string}
            onClick={() => setLaunchCalc(true)}
          />
        </div>
      </form>
    </Card>
  );
};

export default applyErrorBoundary(ScoreForm);
