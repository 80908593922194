import classNames from "classnames";
import { SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQueries, useQuery } from "react-query";
import styled from "styled-components";
import { useAppTheme, useConfirmationModal } from "../../hooks";
import { scoreFormModels } from "../../models/scoreFormModels";
import { scoreModels } from "../../models/scoreModels";
import * as ScoreSetService from "../../services/ScoreSetService";
import { dateToHumanReadable } from "../../utils/date";

import {
  GroundButtonTypes,
  GroundButtonVariants,
  GroundCheckbox,
  GroundIcon,
  GroundToastType,
  GroundToastVariant,
  useGroundToast,
} from "@greenvulcano/ground-react";
import Card from "../../shared-components/Card/Card";
import CustomButton from "../../shared-components/CustomButton/CustomButton";
import RequestIndicator from "../../shared-components/RequestIndicator/RequestIndicator";
import ApplyScoreSetModal from "../ApplyScoreSetModal/ApplyScoreSetModal";
import ViewChartModal from "../ViewChartModal/ViewChartModal";
import ViewScoreModal from "../ViewScoreModal/ViewScoreModal";
import FiltersViewContent from "./FiltersViewContent/FiltersViewContent";
import SavedScoreStatus from "./SavedScoreStatus/SavedScoreStatus";

import { settingsDefaults } from "../../config/settings-defaults";
import { useScoreSetList } from "../../hooks/queries";
import { actionsModels } from "../../models/actionsModels";
import { chartModels } from "../../models/chartModels";
import { serviceModels } from "../../models/serviceModels";
import { FleetsService } from "../../services/FleetsService";
import { useAppDispatch } from "../../store/hooks";
import {
  setIsEditModeActive,
  setShouldReset,
  updateScoreSetToLoad,
} from "../../store/scoreFormSlice";
import { getChartOptions } from "../../utils/charts";
import "./ListOfSavedScore.scss";
import DeleteScoreSetModal from "../DeleteScoreSetModal/DeleteScoreSetModal";
import { prepareDataForLoading } from "../../utils";
import { queryClient } from "../../App";
import { executeIfSessionActive } from "../../utils/errors";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

const filtersInitState: ScoreSetService.getScoreSetsInterface = {
  name: "",
  dates: {
    start: undefined,
    end: undefined,
  },
  checkedStatuses: [],
};

const createScoreSetNameWithId = (id: string | number) => `ScoreSet.${id}`;

const Header = styled.div`
  border-bottom-color: ${(props: any) =>
    props.theme.palettes.borderQuaternaryAlpha60};

  .toggle-button {
    background: ${(props: any) => props.theme.palettes.secondary};
  }
`;

const ListItem = styled.div`
  border-top-color: ${(props: any) =>
    props.theme.palettes.borderQuaternaryAlpha60};
`;

const ButtonsWrapper = styled.div`
  border-top-color: ${(props: any) =>
    props.theme.palettes.borderQuaternaryAlpha60};
`;

const ListOfSavedScore = () => {
  const theme = useAppTheme();
  const { t } = useTranslation("main");
  const appDispatch = useAppDispatch();
  const groundToast = useGroundToast();
  const navigate = useNavigate();
  const confirmationModal = useConfirmationModal();
  const [timer, setTimer] = useState<ReturnType<typeof setTimeout> | null>(
    null
  );
  const [filters, setFilters] = useState(filtersInitState);
  const [isFiltersView, setIsFiltersView] = useState(false);
  const [checkedItemsIds, setCheckedItemIds] = useState([] as string[]);
  const [defaultScoreSetIds, setDefaultScoreSetIds] = useState<string[]>([]);
  const [readyScoreSetIds, setReadyScoreSetIds] = useState<string[]>([]);
  const [notCalculatedScoreSetIds, setNotCalculatedScoreSetIds] = useState<
    string[]
  >([]);
  const [inProgressScoreSetIds, setInProgressScoreSetIds] = useState<string[]>(
    []
  );
  const [errorScoreSetIds, setErrorScoreSetIds] = useState<string[]>([]);
  const [
    tripsDistributionOneSecondQueryEnabled,
    setTripsDistributionOneSecondQueryEnabled,
  ] = useState(false);
  const [
    tripsDistributionFiveMinutesQueryEnabled,
    setTripsDistributionFiveMinutesQueryEnabled,
  ] = useState(false);
  // ! setTripsDistributionArray must have same order as the queries in tripsDistributionQueries
  const {
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const formValues = watch();


  const scoreSetListQuery = useScoreSetList({filters, onSuccess : (data) => {
		setIdArray(data)
  }, onError: (error) => {
    executeIfSessionActive({
      error,
      dispatch: appDispatch,
      navigate,
      callbackSessionExpired: () =>
        groundToast.show({
          type: GroundToastType.alert,
          variant: GroundToastVariant.featured,
          message: String(error),
        }),
    });
  }});
	const scoreSetDetailsQuery = useQuery({
		queryKey: ["scoreSetDetails", checkedItemsIds[0]],
		queryFn: () => ScoreSetService.getScoreSetDetails(checkedItemsIds[0]),
		enabled: false,
		onError: (error: AxiosError) => {
			executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
					}),
			});
		},
	});

  const tripsDistributionFiveMinutesQuery = useQuery({
    queryKey: ["tripsDistributionFiveMinutes", { checkedItemsIds }],
    queryFn: () =>
      ScoreSetService.getTripsDistributionFiveMinutes(checkedItemsIds),
    enabled: tripsDistributionFiveMinutesQueryEnabled,
    onSuccess: (data: any) => {
      setTripsDistributionFiveMinutesQueryEnabled(false);
    },
    onError: (error : AxiosError) => {
      setTripsDistributionFiveMinutesQueryEnabled(false);
      executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
					}),
			});
    },
  });
  const tripsDistributionOneSecondQuery = useQuery({
    queryKey: ["tripsDistributionOneSecond", { checkedItemsIds }],
    queryFn: () =>
      ScoreSetService.getTripsDistributionOneSecond(checkedItemsIds),
    enabled: tripsDistributionOneSecondQueryEnabled,
    onSuccess: (data: any) => {
      setTripsDistributionOneSecondQueryEnabled(false);
    },
    onError: (error : AxiosError) => {
      setTripsDistributionOneSecondQueryEnabled(false);
      executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
					}),
			});
    },
  });

  const scoreSetToDelete = useMutation({
    mutationFn: (checkedItemsIds: number[]) => {
      return ScoreSetService.deleteScoreSet(checkedItemsIds);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["scoreSetList"]);
      confirmationModal.close();
      groundToast.show({
        type: GroundToastType.success,
        variant: GroundToastVariant.featured,
        message: t("toastMessages.deletionSuccessful"),
      });
    },
    onError: (error : AxiosError) => {
      confirmationModal.close();
      executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
					}),
			});
    },
  });

  const applyScoreSetMutation = useMutation({
    mutationFn: (fleetID: string) => {
      return FleetsService.applyFleet(
        checkedItemsIds[0],
        fleetID
      );
    },
    onSuccess: () => {
      confirmationModal.close();
      groundToast.show({
        type: GroundToastType.success,
        variant: GroundToastVariant.featured,
        message: t("toastMessages.scoreSetSuccessfullyApplied"),
      });
    },
    onError: (error : AxiosError) => {
      confirmationModal.close();
      executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
					}),
			});
    },
  });

  const getScoreSetName = (idItem: string) => {
    const scoreSet = scoreSetListQuery.data!.find(
      (item: scoreModels.scoreSet) => String(item.id) === idItem
    );
    return scoreSet!.name || createScoreSetNameWithId(idItem);
  };

  const getScoreSetNames = () => {
    const scoreSetNames = checkedItemsIds.map((idItem) =>
      getScoreSetName(idItem)
    );

    return scoreSetNames.filter(Boolean);
  };

  const disableButton = (buttonID: actionsModels.actionsEnum) => {
    const disableButtonsWhen = (maxIDsCount: number) => {
      if (!checkedItemsIds.length) {
        return true;
      }

      return checkedItemsIds.length > maxIDsCount;
    };

    const checkedItemsIdsContain = {
      default: checkedItemsIds.some((id) => defaultScoreSetIds.includes(id)),
      ready: checkedItemsIds.some((id) => readyScoreSetIds.includes(id)),
      notCalculated: checkedItemsIds.some((id) =>
        notCalculatedScoreSetIds.includes(id)
      ),
      inProgress: checkedItemsIds.some((id) =>
        inProgressScoreSetIds.includes(id)
      ),
      error: checkedItemsIds.some((id) => errorScoreSetIds.includes(id)),
    };

    switch (buttonID) {
      case actionsModels.actionsEnum.EDIT:
        return (
          disableButtonsWhen(1) ||
          tripsDistributionFiveMinutesQuery.isLoading ||
          tripsDistributionOneSecondQuery.isLoading ||
          checkedItemsIdsContain.default || checkedItemsIdsContain.inProgress
        );
      case actionsModels.actionsEnum.VIEW:
        return (
          disableButtonsWhen(1) ||
          tripsDistributionFiveMinutesQuery.isLoading ||
          tripsDistributionOneSecondQuery.isLoading
        );

      case actionsModels.actionsEnum.CHART:
        return (
          disableButtonsWhen(5) ||
          scoreSetDetailsQuery.isLoading ||
          !checkedItemsIdsContain.ready 
        );

      case actionsModels.actionsEnum.APPLY:
        return (
          disableButtonsWhen(1) ||
          scoreSetDetailsQuery.isLoading ||
          tripsDistributionFiveMinutesQuery.isLoading ||
          tripsDistributionOneSecondQuery.isLoading ||
          checkedItemsIdsContain.default ||
          !checkedItemsIdsContain.ready
        );
      case actionsModels.actionsEnum.DELETE:
        return (
          disableButtonsWhen(1) ||
          scoreSetDetailsQuery.isLoading ||
          checkedItemsIdsContain.default || checkedItemsIdsContain.inProgress
        );

      default:
        return true;
    }
  };

  const setIdArray = (data: scoreModels.scoreSet[]) => {
    const tempDefaultScoreSetIds: SetStateAction<string[]> = [];
    const tempReadyScoreSetIds: SetStateAction<string[]> = [];
    const tempNotCalculatedScoreSetIds: SetStateAction<string[]> = [];
    const tempInProgressScoreSetIds: SetStateAction<string[]> = [];
    const tempErrorScoreSetIds: SetStateAction<string[]> = [];

    data.forEach((scoreSet) => {
      if (scoreSet.is_default) tempDefaultScoreSetIds.push(scoreSet.id);
      if (scoreSet.id_status === scoreModels.StateTypes.error)
        tempErrorScoreSetIds.push(scoreSet.id);
      if (scoreSet.id_status === scoreModels.StateTypes.ready)
        tempReadyScoreSetIds.push(scoreSet.id);
      if (scoreSet.id_status === scoreModels.StateTypes.notCalculated)
        tempNotCalculatedScoreSetIds.push(scoreSet.id);
      if (scoreSet.id_status === scoreModels.StateTypes.inProgress)
        tempInProgressScoreSetIds.push(scoreSet.id);
    });

    setDefaultScoreSetIds(tempDefaultScoreSetIds);
    setReadyScoreSetIds(tempReadyScoreSetIds);
    setNotCalculatedScoreSetIds(tempNotCalculatedScoreSetIds);
    setInProgressScoreSetIds(tempInProgressScoreSetIds);
    setErrorScoreSetIds(tempErrorScoreSetIds);
  };

  const disableCheckboxes = () => {
    return (
      scoreSetDetailsQuery.isLoading ||
      tripsDistributionFiveMinutesQuery.isLoading ||
      tripsDistributionOneSecondQuery.isLoading
    );
  };

  const openViewScoreModal = (data: scoreFormModels.scoreSetEvent[]) => {
    confirmationModal.open(
      <ViewScoreModal
        scoreSet={data}
        scoreSetName={getScoreSetName(checkedItemsIds[0])}
        scoreSetID={checkedItemsIds[0]}
      />
    );
  };

  const openDistributionChartModal = (
    dataMatrix: (scoreModels.chartDataSet | undefined)[],
  ) => {
    let message = t("toastMessages.noDataFound");

    const emptyChartDataSetNames = dataMatrix
      .map((chartDataSetArray) => {
        if (chartDataSetArray && !chartDataSetArray.chart_data?.length) {
          return chartDataSetArray.name;
        }
      })
      .filter((name) => name);

    if (emptyChartDataSetNames.length > 0) {
      groundToast.show({
        type: GroundToastType.info,
        variant: GroundToastVariant.featured,
        message: `${message}: ${emptyChartDataSetNames.join(", ")}`,
      });
    }

    const scoreSetsInfo = checkedItemsIds.map((id: string) => {
      const scoreSet = scoreSetListQuery.data!.find(
        (item: scoreModels.scoreSet) => String(item.id) === id
      );

      return {
        id,
        name: scoreSet!.name || createScoreSetNameWithId(id),
      };
    });
    const chartDataArray: chartModels.ChartData[] = dataMatrix
      .filter((chartDataSetArray) => chartDataSetArray?.chart_data?.length)
      .map((data, index) => {
        return {
          id: index.toString(),
          options: getChartOptions({
            t,
            theme,
            data: data?.chart_data,
            scoreSetsInfo,
          }),
          style: settingsDefaults.chartStyle,
          name:
            data?.name ??
            `${t("common.name")} ${t("common.notFound").toLowerCase()}`,
        };
      });
    if (chartDataArray.length) {
      confirmationModal.open(
        <ViewChartModal chartDataArray={chartDataArray}  />
      );
    //   setChartModalShouldOpen(false);
    }
  };

  const onChangeCheckedHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedItemIds((ids: string[]) => [...ids, id]);
    } else {
      setCheckedItemIds((ids: string[]) => {
        return ids.filter((elem: string) => elem !== id);
      });
    }
  };

  const onClickViewScore = async () => {
    const queryData = await scoreSetDetailsQuery.refetch();
    if (queryData.isSuccess) openViewScoreModal(queryData.data);
  };

  const onClickEditScore = async () => {
    const queryData = await scoreSetDetailsQuery.refetch();
    const scoreSetID = checkedItemsIds[0];
    if (queryData.data) {
      appDispatch(
        updateScoreSetToLoad({
          scoreSetID,
          scoreSetName: getScoreSetName(checkedItemsIds[0]),
          data: prepareDataForLoading(queryData.data),
        })
      );
      appDispatch(setIsEditModeActive(true));
      appDispatch(setShouldReset(true));
    }
  };

  const onClickApply = () => {
    confirmationModal.open(
      <ApplyScoreSetModal
        scoreSetName={getScoreSetName(checkedItemsIds[0])}
        navigate={navigate}
        onAction={(fleetID: string) => {
          applyScoreSetMutation.mutate(fleetID);
        }}
      />
    );
  };
  const onClickDelete = () => {
    confirmationModal.open(
      <DeleteScoreSetModal
        scoreSetsName={getScoreSetNames()}
        onAction={() => {
          scoreSetToDelete.mutate(checkedItemsIds.map((id) => parseInt(id)));
        }}
      />
    );
  };

  const onClickViewChart = async () => {
    let fiveMinuteData = tripsDistributionFiveMinutesQuery.data;
    let oneSecondData = tripsDistributionOneSecondQuery.data;

    if (
      tripsDistributionFiveMinutesQuery.status ===
        serviceModels.statusEnum.IDLE ||
      tripsDistributionFiveMinutesQuery.isStale
    ) {
      const fiveMinutesQuery =
        await tripsDistributionFiveMinutesQuery.refetch();
      fiveMinuteData = fiveMinutesQuery.data;
    }
    if (
      tripsDistributionOneSecondQuery.status ===
        serviceModels.statusEnum.IDLE ||
      tripsDistributionOneSecondQuery.isStale
    ) {
      const oneSecondQuery = await tripsDistributionOneSecondQuery.refetch();
      oneSecondData = oneSecondQuery.data;
    }
    openDistributionChartModal([fiveMinuteData, oneSecondData]);
  };


  useEffect(() => {
    // Cloning object
    const newFilters = JSON.parse(JSON.stringify(filtersInitState));

    for (const key in formValues) {
      if (/^score_state-[1-9]+$/.test(key)) {
        if (formValues[key]) {
          const scoreStateID = Number(key.split("-")[1]);

          newFilters.checkedStatuses.push(scoreStateID);
        }
      } else {
        newFilters[key as keyof ScoreSetService.getScoreSetsInterface] =
          formValues[key];
      }
    }

    if (timer) {
      clearTimeout(timer);
    }

    setTimer(setTimeout(() => setFilters(newFilters), 500));

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    setCheckedItemIds([]);
  }, [
    Array.isArray(scoreSetListQuery.data) &&
      scoreSetListQuery.data.map((item: any) => item.id).join(),
  ]);

  return (
    <Card className="score-set-list-wrapper flex flex-columns">
      {!(scoreSetListQuery.isSuccess || isFiltersView) ? (
        <RequestIndicator
          isLoading={!scoreSetListQuery.isError}
          spinnerOptions={{ scale: 0.7 }}
        />
      ) : (
        <>
          <Header
            theme={theme}
            className="header flex flex-space-between flex-vcenter"
          >
            <h3>
              {isFiltersView ? t("common.filters") : t("common.savedScore")}
            </h3>
            <button
              type="button"
              className="toggle-button flex flex-center"
              onClick={() => setIsFiltersView((value) => !value)}
            >
              {isFiltersView ? (
                <GroundIcon svgIcon="close" width="14" />
              ) : (
                <GroundIcon svgIcon="filters" width="14" />
              )}
            </button>
          </Header>
          <div
            className={classNames("inner-wrapper flex-grow flex flex-columns", {
              "scroll-content": !isFiltersView,
            })}
          >
            <form className={classNames("mt-3", { hide: !isFiltersView })}>
              <FiltersViewContent control={control} errors={errors} />
            </form>
            {!(
              Array.isArray(scoreSetListQuery.data) &&
              scoreSetListQuery.data.length
            ) ? (
              <p
                className={classNames("mt-3 text-small", {
                  hide: isFiltersView,
                })}
              >
                {t("errors.noResults")}
              </p>
            ) : (
              scoreSetListQuery.data.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    theme={theme}
                    className={classNames(
                      `list-item flex flex-space-between flex-vcenter gap-1 ${
                        item.is_default ? "is-default-score" : ""
                      }`,
                      { hide: isFiltersView }
                    )}
                  >
                    <div className="flex flex-vcenter list-item-checkbox-and-name">
                      <GroundCheckbox
                        color="secondary"
                        disabled={disableCheckboxes()}
                        checked={checkedItemsIds.includes(item.id)}
                        onChange={(event: any) =>
                          onChangeCheckedHandler(event.target.checked, item.id)
                        }
                      />
                      <span title={item.name}>
                        {item.name || createScoreSetNameWithId(item.id)}
                      </span>
                    </div>
                    <div className="flex flex-vcenter gap-2">
                      <strong className="text-xsmall">
                        {dateToHumanReadable(item.date)}
                      </strong>
                      <SavedScoreStatus idStatus={item.id_status} />
                    </div>
                  </ListItem>
                );
              })
            )}
          </div>
          <ButtonsWrapper
            theme={theme}
            className={classNames("buttons-wrapper flex gap-1", {
              "flex-hcenter": isFiltersView,
              "flex-space-between": !isFiltersView,
            })}
          >
            {isFiltersView ? (
              <>
                <CustomButton
                  className="filters-button"
                  type={GroundButtonTypes.button}
                  variant={GroundButtonVariants.ghost}
                  text={t("common.reset") as string}
                  onClick={() => reset()}
                />
                <CustomButton
                  className="filters-button"
                  type={GroundButtonTypes.button}
                  isLoading={scoreSetListQuery.isLoading}
                  onClick={() => setIsFiltersView(false)}
                  text={
                    t("common.showNResults", {
                      count: Array.isArray(scoreSetListQuery.data)
                        ? scoreSetListQuery.data.length
                        : 0,
                    }) as string
                  }
                />
              </>
            ) : (
              <>
                <CustomButton
                  className="scoreset-list-button"
                  disabled={disableButton(actionsModels.actionsEnum.VIEW)}
                  type={GroundButtonTypes.button}
                  variant={GroundButtonVariants.ghost}
                  text={t("common.viewScore") as string}
                  isLoading={scoreSetDetailsQuery.isLoading}
                  onClick={onClickViewScore}
                />
                <CustomButton
                  className="scoreset-list-button"
                  disabled={disableButton(actionsModels.actionsEnum.EDIT)}
                  type={GroundButtonTypes.button}
                  variant={GroundButtonVariants.ghost}
                  text={t("common.editScore") as string}
                  isLoading={scoreSetDetailsQuery.isLoading}
                  onClick={onClickEditScore}
                />
                <CustomButton
                  className="scoreset-list-button"
                  disabled={disableButton(actionsModels.actionsEnum.DELETE)}
                  type={GroundButtonTypes.button}
                  variant={GroundButtonVariants.ghost}
                  text={t("common.deleteScore") as string}
                  onClick={onClickDelete}
                />
                <CustomButton
                  className="scoreset-list-button"
                  disabled={disableButton(actionsModels.actionsEnum.CHART)}
                  type={GroundButtonTypes.button}
                  variant={GroundButtonVariants.ghost}
                  text={t("common.viewGraph") as string}
                  isLoading={
                    tripsDistributionFiveMinutesQuery.isLoading ||
                    tripsDistributionOneSecondQuery.isLoading
                  }
                  onClick={onClickViewChart}
                />
                <CustomButton
                  className="scoreset-list-button"
                  disabled={disableButton(actionsModels.actionsEnum.APPLY)}
                  type={GroundButtonTypes.button}
                  variant={GroundButtonVariants.ghost}
                  text={t("common.apply") as string}
                  onClick={onClickApply}
                />
              </>
            )}
          </ButtonsWrapper>
        </>
      )}
    </Card>
  );
};

export default ListOfSavedScore;
