import { useAppTheme } from "../../../hooks";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { scoreModels } from "../../../models/scoreModels";
import { useAppSelector } from "../../../store/hooks";
import { selectFunctionality } from "../../../store/functionalitiesSlice";
import { getAppLanguage } from "../../../config";
import { languageModels } from "../../../models/languageModel";

interface SavedScoreStatusInterface {
	idStatus: scoreModels.StateTypes;
}

const SavedScoreStatusWrapper = styled.div`
	background: ${(props: any) => props.theme.palettes[props.color]};
	border-radius: 50%;
	width: 14px;
	height: 14px;

	&:hover {
		cursor: help;
	}
`;

const SavedScoreStatus = ({ idStatus }: SavedScoreStatusInterface) => {
	const { t } = useTranslation("main");
	const theme = useAppTheme();
	const scoreStates: scoreModels.statesList[] = useAppSelector(selectFunctionality("scoreStates"));

	let color = "";
	const status = scoreStates.find(
		(item) => String(item.value) === String(idStatus)
	);

	const label = status && status.label_translated
    ? status.label_translated[getAppLanguage() as languageModels.languageEnum]
    : t("common.unkown");

	switch (idStatus) {
		case scoreModels.StateTypes.ready:
			color = "success";
			break;

		case scoreModels.StateTypes.inProgress:
			color = "inProgress";
			break;

		case scoreModels.StateTypes.notCalculated:
			color = "secondary";
			break;

		case scoreModels.StateTypes.error:
			color = "danger";
			break;

		default:
			color = "disabled";
			break;
	}

	return (
		<SavedScoreStatusWrapper
			theme={theme}
			color={color}
			title={label}
		/>
	);
};

export default SavedScoreStatus;
