import moment from "moment";
import { useTranslation } from "react-i18next";
import { attemptTranslation } from "../../../utils/i18n";

import { settingsDefaults } from "../../../config/settings-defaults";
import { dateModels } from "../../../models/dateModels";
import { scoreModels } from "../../../models/scoreModels";
import { selectFunctionality } from "../../../store/functionalitiesSlice";
import { useAppSelector } from "../../../store/hooks";

import { GroundIcon } from "@greenvulcano/ground-react";
import Checkbox from "../../../shared-components/RHF-input-wrappers/Checkbox/Checkbox";
import DateRangePicker from "../../../shared-components/RHF-input-wrappers/DateRangePicker/DateRangePicker";
import TextInput from "../../../shared-components/RHF-input-wrappers/TextInput/TextInput";

import SavedScoreStatus from "../SavedScoreStatus/SavedScoreStatus";
import "./FiltersViewContent.scss";
import { getAppLanguage } from "../../../config";
import { languageModels } from "../../../models/languageModel";
import { unescape } from "querystring";

const FiltersViewContent = ({ control, errors }: Record<string, any>) => {
  const { t } = useTranslation("main");
  const scoreStates: scoreModels.statesList[] = useAppSelector(selectFunctionality("scoreStates"));

  return (
    <div className="filter-view-content flex flex-columns gap-2">
      <TextInput
        fluid
        name="name"
        placeholder={t("common.setName") as string}
        control={control}
        errors={errors}
        height="48"
      />
      <DateRangePicker
        fluid
        alignRight
        name="dates"
        height="48"
        control={control}
        errors={errors}
        onChange={() => null}
        icon={<GroundIcon color="textLight" svgIcon="calendar" width="22" />}
        minDate={
          settingsDefaults.monthsToFilter
            ? (moment()
                .subtract(settingsDefaults.monthsToFilter, "months")
                .format("YYYY-MM-DD") as dateModels.dateString)
            : undefined
        }
        maxDate={moment().format("YYYY-MM-DD") as dateModels.dateString}
      />
      <div className="flex flex-columns gap-1/2">
        <h4 className="text-small mt-1 mb-1">{t("common.status")}</h4>
        {scoreStates.map((item) => {
          const name = "score_state-" + item.value;

          return (
            <div key={name} className="checkbox-with-status-container">
              <Checkbox
                id={name}
                color="secondary"
                errors={errors}
                control={control}
                name={name}
              />
              <SavedScoreStatus
                idStatus={item.value as scoreModels.StateTypes}
              />
              <span>
                {
                  item &&
                  item.label_translated[
                    getAppLanguage() as languageModels.languageEnum
                  ]}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FiltersViewContent;
