import { palettes, palettesSharedWithGround } from "./palettes";
import { svgIconsData } from "./svg-icons-data";

import { groundDefaults } from "./ground-overrides/defaults";
import { groundPresetSizes } from "./ground-overrides/preset-sizes";
import { distributionChartColors } from "./distribution-chart-colors";


export const DefaultTheme: Record<string, any> = {
  name: 'DefaultTheme',
  fontFamilies: {
    primary: 'Roboto'
  },
  palettes: { ...palettes, ...palettesSharedWithGround },
  defaults: groundDefaults,
  presetSizes: groundPresetSizes,
  svgIconsData,
  distributionChartColors
};