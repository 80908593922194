import { useLayoutEffect } from "react";
import { useQuery } from "react-query";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { applyErrorBoundary } from "../../../utils/error-boundary";
import { useUserQuery } from "../../../hooks/queries";
import { setDocumentTitle } from "../../../config";
import { FunctionalitiesService } from "../../../services/FunctionalitiesService";

import { useAppDispatch } from "../../../store/hooks";
import {
  loadFunctionalities,
  loadEventTypes,
  loadDisabledEventTypes,
} from "../../../store/functionalitiesSlice";

import Header from "../../../shared-components/Header/Header";
import RequestIndicator from "../../../shared-components/RequestIndicator/RequestIndicator";
import { AxiosError } from "axios";
import { GroundToastType, GroundToastVariant, useGroundToast } from "@greenvulcano/ground-react";
import { executeIfSessionActive } from "../../../utils/errors";

const Root = () => {
  const { t } = useTranslation("main");
  const location = useLocation();
  const userQuery = useUserQuery();
  const appDispatch = useAppDispatch();
  const groundToast = useGroundToast();
  const navigate = useNavigate();
  const eventTypesQuery = useQuery({
    queryKey: ["eventTypes"],
    queryFn: FunctionalitiesService.getEventTypes,
    enabled: userQuery.isSuccess,
    onSuccess: (data: any) => {
      appDispatch(loadEventTypes(data));
      appDispatch(loadDisabledEventTypes(data));
    },
    onError: (error: AxiosError) => {
      executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
					}),
			});
    }
  });

  useQuery({
    queryKey: ["getScoreStates"],
    queryFn: FunctionalitiesService.getScoreStates,
    enabled: userQuery.isSuccess,
    onSuccess: (data: any) => {
      appDispatch(loadFunctionalities(data));
    },
    onError: (error: AxiosError) => {
      executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
					}),
			});
    }
  });

  useLayoutEffect(() => {
    setDocumentTitle();
  }, [location.pathname]);

  if (userQuery.isIdle || userQuery.isError) {
    return <Navigate to="/login" />;
  }

  if (userQuery.isSuccess && /^\/+admin\/*$/i.test(location.pathname)) {
    return <Navigate to="./home" />;
  }

  return eventTypesQuery.isSuccess ? (
    <>
      <Header />
      <main className="flex-grow flex flex-columns">
        <Outlet />
      </main>
    </>
  ) : (
    <RequestIndicator
      delay={500}
      className="flex-grow"
      isLoading={!(eventTypesQuery.isError || userQuery.isError)}
      errorMessage={t("errors.failedRetrivingAppRequiredData") as string}
    />
  );
};

export default applyErrorBoundary(Root);
