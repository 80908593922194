import axios, { AxiosError } from "axios";
import { getApiEndpoint } from "../config";
import { isObjectEmpty } from "../utils";
import {
	getStorageItem,
	setStorageItem,
	removeStorageItem,
} from "../utils/local-storage";
import i18n from "../i18n";

export const AuthService = {
	getToken: () => {
		const authObj = getStorageItem("auth");

		if (isObjectEmpty(authObj) || !authObj.token) {
			return "";
		}

		return authObj.token;
	},
	getAuthHeaders: () => {
		const token = AuthService.getToken();

		if (!token) {
			return { headers: {} };
		}

		return { headers: { Authorization: `Bearer ${token}` } };
	},
	login: async ({ username, password }: any): Promise<any> => {
		try {
			const { data: responseData } = await axios.post(getApiEndpoint("login"), {
				username,
				password,
			});

			setStorageItem("auth", responseData);

			return Promise.resolve(responseData);
		} catch (error) {
			const typeError = error as AxiosError;
      let errorMessage = "";
      if (typeError.response?.status && 401 === typeError.response?.status) {
				errorMessage = i18n.t("main:errors.usernameOrPasswordInvalid");
      } else {
				errorMessage = `${i18n.t("main:errors.requestFailedWithErrors")} ${
					 typeError.message ? typeError.message : typeError.response?.data}`;
			}
			return Promise.reject(errorMessage);
		}
	},
	logout: async (): Promise<any> => {
		try {
			const { data: responseData } = await axios.post(
				getApiEndpoint("logout"),
				{},
				AuthService.getAuthHeaders()
			);

			return Promise.resolve(responseData);
		} catch (error) {
			const typeError = error as AxiosError;
			const errorMessage = typeError.response
				? typeError.response.data
				: typeError;

			return Promise.reject(errorMessage);
		} finally {
			removeStorageItem("auth");
		}
	},

	// This is the first ever service to be call on every page load
	getUserInfo: async () => {
		const superAdminIdLevel = "1";
		try {
			if (!AuthService.getToken()) {
				throw new Error(i18n.t("main:errors.missingToken") ?? "Missing Token");
			}

			const { data: responseData } = await axios.get(
				getApiEndpoint("userInfo"),
				AuthService.getAuthHeaders()
			);
			if (superAdminIdLevel !== String(responseData.data.profile.idLevel)) {
				AuthService.logout();
				throw new Error(
					i18n.t("main:errors.notAuthorized") ??
						"Not authorized to enter in EDS"
				);
			}

			return Promise.resolve({
				...responseData.data,
				fullname:
					responseData.data.firstName + " " + responseData.data.lastName,
				role: responseData.data.profile.description,
			});
		} catch (error: any) {
			const typeError = error.isAxiosError ? (error as AxiosError) : error;
			removeStorageItem("auth");
			return Promise.reject(typeError.message || "errors.credentialsRequired");
		}
	},
	restorePassword: async ({ username }: any): Promise<any> => {
		try {
			const response = await axios.post(getApiEndpoint("restorePassword"), {
				username,
			});

			setStorageItem("auth", response.data);

			return Promise.resolve(response.data);
		} catch (error) {
      const typeError = error as AxiosError;
      let errorMessage = "";
			if (typeError.response?.status && 403 === typeError.response?.status) {
        errorMessage = i18n.t('main:errors.usernameNotFound');
			}else {
        errorMessage = `${i18n.t("main:errors.requestFailedWithErrors")} ${typeError.message ? typeError.message : typeError.response?.data}`;
      }
			return Promise.reject(errorMessage);
		}
	},
};
