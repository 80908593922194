export namespace scoreFormModels {
  export type scoreSetEvent = {
    id?: number;

    // Received from BE
    idEventType: number;
    isActive: boolean;
    highSeverityValueTo: string | null;
    highSeverityWeight: number | string | null;
    highSeverityValueFrom: string | null;
    mediumSeverityWeight: number | string | null;
    mediumSeverityValueTo: string | null;
    mediumSeverityValueFrom: string | null;
    lowSeverityWeight: number | string | null;
    lowSeverityValueTo: string | null;
    lowSeverityValueFrom: string | null;
  };

  export enum valueTypeEnum {
    number = "number",
    time = "time",
    hhmm = "hhmm",
    text = "text",
  }
  export type eventTypeBE = {
    id: string;
    is_active: string;
    max_value: string | null;
    min_value: string | null;
    name: string;
    order_position: string;
  };
  export type eventType = {
    unit: valueTypeEnum;
    unitDescr?: string;
    disabled?: boolean;
    severityCanBeIncomplete?: boolean;
    id?: string;
    name: string;
    max_value?: string;
    min_value?: string;
  };
  export enum SeverityIdEnum {
    empty = "",
    low = "low",
    medium = "medium",
    high = "high",
  }
  export enum FieldIdEnum {
    empty = "",
    from = "from",
    to = "to",
    weight = "weight",
    isActive = "isActive",
  }
  export type currentSeverityCompletionStateType = {
    [key: string]: {
      [key: string]: { [key: string]: string | boolean };
    };
  };
}
