import { RouterProvider } from "react-router-dom";
import styled from "styled-components";
import { Provider as ReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools';
import { useTranslation } from "react-i18next";
import { applyErrorBoundary } from "./utils/error-boundary";
import { getAppConfig, getAppLocale, getThemeToLoad } from "./config";

import { store } from "../src/store";
import { router } from "./routing/router";

import {
	GroundProvider,
	GroundToastProviderDirections,
} from "@greenvulcano/ground-react";
import ConfirmationModalProvider from "./shared-components/ConfirmationModalProvider/ConfirmationModalProvider";

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: getAppConfig("staleTime", 10 * 1000),
			cacheTime: getAppConfig("cacheTime", 5 * 60 * 1000),
		},
	},
});
const toastOptions = {
	direction: GroundToastProviderDirections.center,
};

// Necessary hack to overcome the lack of support for
// internationalization of Ground.
const getDateRangePickerAdditionalDefaults = ({ t }: Record<string, any>) => {
	return {
		locale: getAppLocale(),
		utilityButtonsText: {
			today: t("ground.today"),
			currentWeek: t("ground.thisWeek"),
			currentMonth: t("ground.thisMonth"),
			lastMonth: t("ground.lastMonth"),
			lastYear: t("ground.lastYear"),
		},
	};
};

const PageWrapper = styled.div`
	color: ${(props: any) => props.theme.palettes.text};
`;

const App = () => {
	const { t } = useTranslation("main");
	const theme = getThemeToLoad();

	theme.defaults.groundDateRangePicker = {
		...theme.defaults.groundDateRangePicker,
		...getDateRangePickerAdditionalDefaults({ t }),
	};

	return (
    <GroundProvider theme={theme} toastOptions={toastOptions}>
      <PageWrapper theme={theme} className="flex-grow flex flex-columns">
        <ReduxProvider store={store}>
          <QueryClientProvider client={queryClient}>
            <ConfirmationModalProvider>
              <RouterProvider router={router} />
            </ConfirmationModalProvider>
            {process.env.NODE_ENV === "development" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
          </QueryClientProvider>
        </ReduxProvider>
      </PageWrapper>
    </GroundProvider>
  );
};

export default applyErrorBoundary(App);
