import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { scoreFormModels } from "../models/scoreFormModels";
import { scoreModels } from "../models/scoreModels";

export interface FunctionalitiesState {
  eventTypes: scoreFormModels.eventType[];
  scoreStates: scoreModels.statesList[];
}

export const functionalitiesSlice = createSlice({
  name: "functionalities",
  initialState: {
    eventTypes: [],
    disabledEventTypes: [],
    scoreStates: [],
  } as FunctionalitiesState,
  reducers: {
    loadFunctionalities: (
      state,
      action: PayloadAction<FunctionalitiesState>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    loadEventTypes: (
      state,
      action: PayloadAction<scoreFormModels.eventType[]>
    ) => {
      return {
        ...state,
        eventTypes: action.payload.map((eventType) => {
          const eventTypeClone = structuredClone(eventType);
          return eventTypeClone;
        }),
      };
    },
    loadDisabledEventTypes: (
      state,
      action: PayloadAction<scoreFormModels.eventType[]>
    ) => {
      return {
        ...state,
        disabledEventTypes: action.payload
          .map((eventType) => (eventType.disabled ? eventType.name : undefined))
          .filter((name) => name),
      };
    },
  },
});

export const { loadFunctionalities, loadEventTypes, loadDisabledEventTypes } =
  functionalitiesSlice.actions;

export const selectFunctionality = (id: string) => (state: RootState) => {
  if (id in state.functionalities) {
    return state.functionalities[id as keyof FunctionalitiesState];
  }

  return [] as any;
};

export default functionalitiesSlice.reducer;
