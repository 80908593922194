import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, SubmitHandler } from "react-hook-form";
import { applyErrorBoundary } from "../../../utils/error-boundary";
import { useNavigate } from "react-router-dom";
import { GroundButton, GroundButtonTypes } from "@greenvulcano/ground-react";
import TextInput from "../../../shared-components/RHF-input-wrappers/TextInput/TextInput";
import LoginTemplate from "../../../shared-components/LoginTemplate/LoginTemplate";
import RouterLink from "../../../shared-components/RouterLink/RouterLink";
import { AuthService } from "../../../services/AuthService";
import MoodyText from "../../../shared-components/MoodyText/MoodyText";
import "./PasswordRecovery.scss";

interface FormValues {
	username: string;
}

const PasswordRecovery = () => {
	const { t } = useTranslation("main");
	const navigate = useNavigate();
	const [serverError, setServerError] = useState("");
	const {
		control,
		handleSubmit,
		watch,
		formState: { errors, isSubmitting },
	} = useForm<FormValues>();

	const username = watch("username");

	const onSubmitHandler: SubmitHandler<FormValues> = async (data) => {
		try {
			await AuthService.restorePassword(data);

			navigate("/login");
		} catch (errorMessage: any) {
			setServerError(errorMessage as string);
		}
	};

	useEffect(() => {
		if (username) {
			setServerError("");
		}
	}, [username]);

	return (
		<LoginTemplate>
			<form
				className="password-recovery-form"
				onSubmit={handleSubmit(onSubmitHandler)}
			>
				<h3>{t("passwordRecovery.title")}</h3>
				<p>{t("passwordRecovery.description")}</p>
				<div className="form-controls">
					<TextInput
						fluid
						autoValidate
						timeoutAutoValidate
						type="username"
						name="username"
						control={control}
						errors={errors}
						rules={{ required: t("common.usernameRequired") as string }}
						readOnly={isSubmitting}
						placeholder={t("common.username") as string}
					/>
					<MoodyText className="error-password-message-">
						{serverError}
					</MoodyText>
					<div className="buttons">
						<div className="flex flex-vcenter flex-space-between width-100">
							<RouterLink to="/login">{t("common.back")}</RouterLink>

							<GroundButton
								type={GroundButtonTypes.submit}
								color="secondary"
								text={t("passwordRecovery.submitText")}
							/>
						</div>
					</div>
				</div>
			</form>
		</LoginTemplate>
	);
};

export default applyErrorBoundary(PasswordRecovery);
