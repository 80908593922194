import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

export interface UserState {
	fullname: string;
	role: string;
}

export const userSlice = createSlice({
	name: "user",
	initialState: {
		fullname: "",
		role: "",
	},
	reducers: {
		update: (state, action: PayloadAction<UserState>) => {
			return {
				...state,
				...action.payload,
			};
		},
	},
});

export const { update: updateUserInfo } = userSlice.actions;

export const selectUserInfo = (state: RootState) => state.user;

export default userSlice.reducer;
