
import { useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useAppTheme, useConfirmationModal } from "../../hooks";

import { GroundButtonVariants, GroundButtonTypes } from "@greenvulcano/ground-react";
import CustomButton from "../CustomButton/CustomButton";
import CloseButton from "../CloseButton/CloseButton";

import { ConfirmationModalInterface } from "./ConfirmationModalInterface";
import "./ConfirmationModal.scss";


const Modal = styled.div`
  background: ${( props: any ) => props.theme.palettes.background};
  
  h3 {
    color: ${( props: any ) => props.theme.palettes.primaryDarkest};
  }
  
  p {
    color: ${( props: any ) => props.theme.palettes.textSecondaryDark};
  }
`;

const ConfirmationModal = ({ 
  title,
  subTitle,
  content,
  readOnly,
  actionDisabled,
  actionLabel,
  cancelLabel,
  buttons,
  size = 'small',
  className,
  confirmationButtonColor,
  onClose,
  onAction = () => false,
}: ConfirmationModalInterface) => {
  const { t }             = useTranslation( 'main' );
  const theme             = useAppTheme();
  const confirmationModal = useConfirmationModal();
  const [ isLoading, setIsLoading ] = useState( false );

  const onCloseModalHandler = () => {
    if ( typeof onClose === 'function' ) {
      onClose();
    }
    
    confirmationModal.close();
  };

  return (
    <Modal
      theme={theme}
      className={classNames(
        'confirmation-modal flex flex-columns gap-3',
        size,
        className
      )}
      onClick={( event ) => event.stopPropagation()}
    >
      <div className='flex flex-columns'>
        <div className='flex flex-space-between flex-rows-reverse'>
          <CloseButton
            disabled={isLoading}
            onClick={onCloseModalHandler}
          />
          <h3>{title}</h3>
        </div>
        {subTitle ? <h4>{subTitle}</h4> : null}
      </div>
      <div className='content flex-grow'>
        {content}
      </div>
      {readOnly ? null : (
        <div className='flex flex-hcenter gap-2 width-100'>
          {buttons ? buttons : (
            <>
              <CustomButton
                disabled={isLoading}
                variant={GroundButtonVariants.ghost}
                type={GroundButtonTypes.button}
                text={cancelLabel || t( 'common.cancel' ) as string}
                onClick={onCloseModalHandler}
              />
              <CustomButton
                type={GroundButtonTypes.button}
                color={confirmationButtonColor}
                disabled={actionDisabled}
                text={actionLabel || t( 'common.confirm' ) as string}
                isLoading={isLoading}
                onClick={() => setIsLoading( Boolean( onAction( confirmationModal.close ) ) )}
              />
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ConfirmationModal;