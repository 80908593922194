import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import classNames from "classnames";
import { useAppTheme } from "../../hooks";
import { getAppLocale, setAppLocale } from "../../config";
import { AuthService } from "../../services/AuthService";

import { ReactComponent as EngFlag } from "../../images/flags/eng.svg";
import { ReactComponent as MxFlag } from "../../images/flags/mex.svg";
import { locales } from "../../config/locales";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { selectUserInfo } from "../../store/userSlice";
import { logout } from "../../store/rootReducer";

import {
  GroundAvatar,
  useGroundToast,
} from "@greenvulcano/ground-react";
import Logo from "../Logo/Logo";
import DisclosingArrow from "../DisclosingArrow/DisclosingArrow";
import ModalMenu from "../ModalMenu/ModalMenu";
import MoodyText from "../MoodyText/MoodyText";

import "./Header.scss";
import { queryClient } from "../../App";


const MainHeader = styled.header`
  background: ${( props: any ) => props.theme.palettes.backgroundDarkest};
  border-bottom-color: ${( props: any ) => props.theme.palettes.borderQuaternary};
  color: ${( props: any ) => props.theme.palettes.textWhite};

  .nav-item {
    &.active,
    &:hover {
      border-bottom-color: ${( props: any ) => props.theme.palettes.secondary};
    }
  }
`;

const ModalWrapper = styled.div`
  .modal-menu-wrapper {
    .trigger {
      &::after {
        border-color: ${( props: any ) => props.theme.palettes.textWhite};
      }
    }
  }

  .user-fullname {
    color: ${( props: any ) => props.theme.palettes.textWhite};
  }

  .user-role {
    color: ${( props: any ) => props.theme.palettes.secondary};
  }

  .lang-buttons-wrapper {
    border-bottom-color: ${( props: any ) => props.theme.palettes.borderSecondary};
  }

  .modal-menu .action-button {
    color: ${( props: any ) => props.theme.palettes.text};

    &:hover {
      color: ${( props: any ) => props.theme.palettes.secondary};
    }
  }
`;

const getFlagSvgComponent = ( locale: string ) => {
  const key = locale.toLowerCase();

  switch ( key ) {
    case 'es-mx':
      return <MxFlag />;

    default:
      return <EngFlag />;
  }
};

const Header = () => {
  const { t }       = useTranslation( 'main' );
  const theme       = useAppTheme();
  const navigate    = useNavigate();
  const groundToast = useGroundToast();
  const appDispatch = useAppDispatch();
  const userInfo    = useAppSelector( selectUserInfo );
  const [ locale, setLocale ] = useState( getAppLocale() );

  const onClickLogout = async () => {
    try {
      await AuthService.logout();
    }
    catch ( error ) {
      console.error( error );
    }
    finally {
      groundToast.removeAll();
      queryClient.removeQueries();
      appDispatch( logout() );
      navigate( '/login' );
    }
  };

  const onClickButton = ( newLocale: string ) => {
    setLocale( newLocale );
    setAppLocale( newLocale );

    window.location.reload();
  };

  const renderTriggerContent = () => (
    <>
      <GroundAvatar
        noBorder
        size='small'
        backgroundColor='backgroundTertiary'
      />
      <span className='user-info'>
        <strong className='user-fullname'>{userInfo.fullname}</strong>
        <span className='user-role'>{userInfo.role || '–'}</span>
      </span>
      <DisclosingArrow
        arrowColor='textWhite'
        className='arrow'
      />
    </>
  );

  return (
    <MainHeader
      theme={theme}
      className='main-header flex flex-space-between flex-vcenter'
    >
      <Logo />
      <ModalWrapper
        theme={theme}
        className='user-info-wrapper'
      >
        <ModalMenu
          actions={[
            {
              i18nKey: 'common.logout',
              callback: onClickLogout
            }
          ]}
          renderTriggerContent={renderTriggerContent}
        >
          <div className='lang-buttons-wrapper flex flex-vcenter'>
            <MoodyText
              color='text'
              className='text-small text-semibold'
            >
              {t( 'common.language' )}
              {': '}
            </MoodyText>
            {locales.map(( item: any ) => {
              return (
                <button
                  key={item.value}
                  className={classNames(
                    'lang-button',
                    { inactive: ( item.value !== locale )}
                  )}
                  onClick={() => onClickButton( item.value )}
                >
                  {getFlagSvgComponent( item.value )}
                </button>
              );
            })}
          </div>
        </ModalMenu>
      </ModalWrapper>
    </MainHeader>
  );
};

export default Header;