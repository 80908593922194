import { applyErrorBoundary } from "../../../utils/error-boundary";

import ListOfSavedScore from "../../../main-components/ListOfSavedScore/ListOfSavedScore";
import ScoreForm from "../../../main-components/ScoreForm/ScoreForm";
import MainContentWrapper from "../../../shared-components/MainContentWrapper/MainContentWrapper";

import "./MainPage.scss";


const MainPage = () => {
	return (
    <MainContentWrapper>
      <div className='main-area flex gap-4'>
        <ScoreForm />
        <ListOfSavedScore />
      </div>
    </MainContentWrapper>
  );
};

export default applyErrorBoundary(MainPage);
