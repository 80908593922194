import { Controller } from "react-hook-form";
import { CheckboxInterface } from "./CheckboxInterface";

import { GroundCheckbox } from "@greenvulcano/ground-react";


const Checkbox = ({
  name = '',
  defaultValue = false,
  control,
  errors = {},
  rules = {},
  ...rest
}: CheckboxInterface ) => {

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
      render={({ field: { value, onChange } }) => {
        return (
          <GroundCheckbox
            erratum={Boolean( errors[name] )}
            checked={value}
            onChange={onChange}
            {...rest}
          />
        );
      }}
    />
  );
};

export default Checkbox;