import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { pdf } from "@react-pdf/renderer";

import ConfirmationModal from "../../shared-components/ConfirmationModal/ConfirmationModal";
import PdfDocument from "./PdfDocument/PdfDocument";

import { ViewChartModalInterface } from "./ViewChartModalInterface";
import "./ViewChartModal.scss";
import TabbedCharts from "../TabbedCharts/TabbedCharts";


const ViewChartModal = ({
  chartDataArray,
}: ViewChartModalInterface ) => {
  const { t } = useTranslation( 'main' );
  const [chartInstance, setChartInstance] = useState<any>(null);
  const [selectedChartName, setCurrentChartName] = useState<string>("")
  const [title, setTitle] = useState("")

  useEffect(() => {
    setTitle(`${t("common.distribution")} - ${selectedChartName}`)
  }, [ selectedChartName]);

  const onActionHandler = () => {
    (async () => {
      try {
        const link = document.createElement( 'a' );
        const blob = await pdf(
          <PdfDocument chartInstance={chartInstance} title={title.toUpperCase()} />
        ).toBlob();

        link.href     = window.URL.createObjectURL( blob );
        link.download = `${title}.pdf`;
        link.click();
      }
      catch ( error ) {
        console.error( error );
      }
    })();
  };

  return (
		<ConfirmationModal
			size="large"
			content={
				<TabbedCharts
					setCurrentChartName={setCurrentChartName}
					setChartInstance={setChartInstance}
					tabbedChartsArray={chartDataArray}
				/>
			}
			title={title}
			cancelLabel={t("common.goBack") as string}
			actionLabel={t("common.exportScore") as string}
			onAction={onActionHandler}
			actionDisabled={!Boolean(chartInstance)}
		/>
	);
};

export default ViewChartModal;