import { GroundLoadingSpinner, GroundTextInput, GroundToastType, GroundToastVariant, useGroundToast } from "@greenvulcano/ground-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useScoreSetList } from "../../hooks/queries";
import ConfirmationModal from "../../shared-components/ConfirmationModal/ConfirmationModal";
import MoodyText from "../../shared-components/MoodyText/MoodyText";
import "./SaveScoreSetModal.scss";
import { SaveScoreSetModalInterface } from "./SaveScoreSetModalInterface";
import { getScoreSetsInterface } from "../../services/ScoreSetService";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getIsEditModeActive, selectScoreSetToLoad } from "../../store/scoreFormSlice";
import { sanitizeString } from "../../utils/strings";
import { executeIfSessionActive } from "../../utils/errors";
import { useConfirmationModal } from "../../hooks";

const emptyFilters: getScoreSetsInterface  = {
	name: '',
	dates: {
		start: undefined,
		end: undefined,
	},
	checkedStatuses: [],
};

const SaveScoreSetModal = ({ onAction, scoreSetName,title,navigate }: SaveScoreSetModalInterface) => {
  const { t } = useTranslation("main")
  const appDispatch = useAppDispatch();
  const groundToast = useGroundToast();
  const confirmationModal = useConfirmationModal();
  const [defaultScoreSetName] = useState(scoreSetName);
  const [scoreSetNameInternal, setScoreSetNameInternal] =
    useState(defaultScoreSetName);
  const scoreSetListQuery = useScoreSetList({
    filters: emptyFilters, onError: (error) => {
    	executeIfSessionActive({
				error,
				dispatch: appDispatch,
				navigate,
				callbackSessionExpired: () =>
					groundToast.show({
						type: GroundToastType.alert,
						variant: GroundToastVariant.featured,
						message: String(error),
          }),
        callbackSessionActive : () => confirmationModal.close()
			});
  }});
  const [existingScoreSetNameList, setExistingScoreSetNameList] = useState<
    string[]
  >([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

	const {
		name: scoreSetToLoadName
	} = useAppSelector( selectScoreSetToLoad );
const isEditModeActive = useAppSelector(getIsEditModeActive)

  const validateScoreSetName = () => {
    const sanitizedScoreName = sanitizeString(scoreSetNameInternal);
    const tempErrorMessages: string[] = [];

    if (sanitizedScoreName.length >= 1 && sanitizedScoreName.length < 3) {
      tempErrorMessages.push(t("errors.scoreSetNameTooShort"));
    }

    const sanitizedScoreNameTrimmedLowercase = sanitizedScoreName
      .toLowerCase()
      .trim();
    const isScoreNameInList = existingScoreSetNameList.includes(
      sanitizedScoreNameTrimmedLowercase
    );
    const isDifferentFromScoreSetToLoadName =
      sanitizedScoreNameTrimmedLowercase !==
      scoreSetToLoadName?.toLowerCase().trim();

    if (
      (isScoreNameInList && !isEditModeActive) ||
      (isEditModeActive &&
        isScoreNameInList &&
        isDifferentFromScoreSetToLoadName)
    ) {
      tempErrorMessages.push(t("errors.scoreSetNameAlreadyExists"));
    }
    setErrorMessages(tempErrorMessages);
  };

  useEffect(() => {
    validateScoreSetName();
  }, [scoreSetNameInternal, existingScoreSetNameList]);

  useEffect(() => {
    setExistingScoreSetNameList(
      scoreSetListQuery.data?.map((scoreSet) =>
        scoreSet.name?.toLowerCase().trim()
      ) || []
    );
  }, [scoreSetListQuery.data]);


  const content = (
    <>
      {scoreSetListQuery.isSuccess && (
        <div className="save-score-set-modal-content">
          <p className="text-small mb-1">{t("modals.changeScoreSetName")}:</p>
          <GroundTextInput
            fluid
            height="48"
            placeholder={t("common.setName") as string}
            value={scoreSetNameInternal}
            onChange={(event: any) => {
              setScoreSetNameInternal(event.target.value);
            }}
            erratum={!!errorMessages.length}
          />
          <MoodyText
            className="text-xsmall ml-1"
            color="danger"
            children={errorMessages.join("&")}
          ></MoodyText>
        </div>
      )}
      {scoreSetListQuery.isLoading && (
        <div className="width-100 flex flex-center">
          <GroundLoadingSpinner />
        </div>
      )}
    </>
  );

  return (
    <ConfirmationModal
      size="small"
      content={content}
      title={title}
      actionLabel={t("common.save") as string}
      actionDisabled={!!errorMessages.length ||!scoreSetNameInternal.trim().length}
      onAction={() => {
        onAction(sanitizeString(scoreSetNameInternal));

        return true;
      }}
    />
  );
};

export default SaveScoreSetModal;
