import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { settingsDefaults } from "../config/settings-defaults";
import { getStorageItem, setStorageItem } from "../utils/local-storage";

import { RootState } from ".";


export interface SettingsState {
  [key: string]: any;
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: (() => {
    const initialState = {} as SettingsState;
    const settings     = getStorageItem( 'settings' ) || {};
  
    for ( const key in settingsDefaults ) {
      initialState[key] = ( key in settings ? settings[key] : settingsDefaults[key] );
    }
    
    return initialState;
  })(),
  reducers: {
    update: ( state, action: PayloadAction<SettingsState> ) => {
      const key = Object.keys( action.payload )[0];

      if ( !( key in state ) ) {
        return state;
      }

      const newSettings = {
        ...state,
        [key]: action.payload[key]
      };
      
      setStorageItem( 'settings', newSettings );

      return newSettings;
    }
  }
});

export const {
  update: updateSetting
} = settingsSlice.actions;

export const selectSetting = ( key: string ) => ( state: RootState ) => ( 
  key in state.settings ? state.settings[key] : ( settingsDefaults[key] ?? '' )
);

export default settingsSlice.reducer;