import styled from "styled-components";
import classNames from "classnames";
import { useAppTheme } from "../../hooks";

import { GroundButton, GroundButtonProps } from "@greenvulcano/ground-react";


const Button = styled( GroundButton )`
  font-size: 0.75rem;
  text-transform: uppercase;
  padding-left: 1rem;
  padding-right: 1rem;

  &:disabled {
    background: ${( props: any ) => props.theme.palettes.disabled};
    color: ${( props: any ) => props.theme.palettes.onDisabled};
  }
`;

const CustomButton = ({
  height = 'medium',
  color = 'secondary',
  className,
  ...otherProps
}: GroundButtonProps ) => {
  const theme = useAppTheme();

  return (
    <Button
      {...otherProps}
      theme={theme}
      height={height}
      color={color}
      className={classNames( 'custom-button', className )}
    />
  );
};

export default CustomButton;