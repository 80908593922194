import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";

type LoginState = {
    hasSessionExpired : boolean
}

export const loginSlice = createSlice({
    name: "login",
    initialState: {
        hasSessionExpired: false,
    } as LoginState,
    reducers: {
        setHasSessionExpired: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                hasSessionExpired: action.payload,
              };
        }
    }
})

export const { setHasSessionExpired } = loginSlice.actions;

export const getHasSessionExpired = (state: RootState) => {
    return state.login.hasSessionExpired;
  }

export default loginSlice.reducer;